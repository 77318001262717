import LogoMinimalista from "../../../../assets/images/logos/logo-minimalista.png"
import "./CorporativeHeader.css"
import { Link } from "react-router-dom"

export const CorporativeHeader = () => {
  return (
    <div className="corporative-header-container">
      <Link to="/">
        <img src={LogoMinimalista} alt="Logo Homekids" />
      </Link>
    </div>
  )
}
