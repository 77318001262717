import principal_0 from "../assets/images/productos-img/0/principal.jpg";
import carousel2_0 from "../assets/images/productos-img/0/carousel2.jpg";
import principal_1 from "../assets/images/productos-img/1/principal.jpg";
import carousel2_1 from "../assets/images/productos-img/1/carousel2.jpg";
import carousel3_1 from "../assets/images/productos-img/1/carousel3.jpg";
import carousel4_1 from "../assets/images/productos-img/1/carousel4.jpg";
import carousel5_1 from "../assets/images/productos-img/1/carousel5.jpg";
import principal_2 from "../assets/images/productos-img/2/principal.jpg";
import carousel2_2 from "../assets/images/productos-img/2/carousel2.jpg";
import carousel3_2 from "../assets/images/productos-img/2/carousel3.jpg";
import carousel4_2 from "../assets/images/productos-img/2/carousel4.jpg";
import carousel5_2 from "../assets/images/productos-img/2/carousel5.jpg";
import principal_3 from "../assets/images/productos-img/3/principal.jpg";
import carousel2_3 from "../assets/images/productos-img/3/carousel2.jpg";
import carousel3_3 from "../assets/images/productos-img/3/carousel3.jpg";
import carousel4_3 from "../assets/images/productos-img/3/carousel4.jpg";
import carousel5_3 from "../assets/images/productos-img/3/carousel5.jpg";
import principal_4 from "../assets/images/productos-img/4/principal.jpg";
import carousel2_4 from "../assets/images/productos-img/4/carousel2.jpg";
import carousel3_4 from "../assets/images/productos-img/4/carousel3.jpg";
import carousel4_4 from "../assets/images/productos-img/4/carousel4.jpg";
import carousel5_4 from "../assets/images/productos-img/4/carousel5.jpg";
import principal_5 from "../assets/images/productos-img/5/principal.jpg";
import carousel2_5 from "../assets/images/productos-img/5/carousel2.jpg";
import carousel3_5 from "../assets/images/productos-img/5/carousel3.jpg";
import carousel4_5 from "../assets/images/productos-img/5/carousel4.jpg";
import carousel5_5 from "../assets/images/productos-img/5/carousel5.jpg";
import principal_6 from "../assets/images/productos-img/6/principal.jpg";
import carousel2_6 from "../assets/images/productos-img/6/carousel2.jpg";
import carousel3_6 from "../assets/images/productos-img/6/carousel3.jpg";
import carousel4_6 from "../assets/images/productos-img/6/carousel4.jpg";
import principal_7 from "../assets/images/productos-img/7/principal.jpg";
import carousel2_7 from "../assets/images/productos-img/7/carousel2.jpg";
import carousel3_7 from "../assets/images/productos-img/7/carousel3.jpg";
import carousel4_7 from "../assets/images/productos-img/7/carousel4.jpg";
import carousel5_7 from "../assets/images/productos-img/7/carousel5.jpg";
import principal_8 from "../assets/images/productos-img/8/principal.jpg";
import carousel2_8 from "../assets/images/productos-img/8/carousel2.jpg";
import carousel3_8 from "../assets/images/productos-img/8/carousel3.jpg";
import carousel4_8 from "../assets/images/productos-img/8/carousel4.jpg";
import carousel5_8 from "../assets/images/productos-img/8/carousel5.jpg";
import principal_9 from "../assets/images/productos-img/9/principal.jpg";
import carousel2_9 from "../assets/images/productos-img/9/carousel2.jpg";
import carousel3_9 from "../assets/images/productos-img/9/carousel3.jpg";
import carousel4_9 from "../assets/images/productos-img/9/carousel4.jpg";
import carousel5_9 from "../assets/images/productos-img/9/carousel5.jpg";
import principal_10 from "../assets/images/productos-img/10/principal.jpg";
import carousel2_10 from "../assets/images/productos-img/10/carousel2.jpg";
import carousel3_10 from "../assets/images/productos-img/10/carousel3.jpg";
import carousel4_10 from "../assets/images/productos-img/10/carousel4.jpg";
import carousel5_10 from "../assets/images/productos-img/10/carousel5.jpg";
import principal_11 from "../assets/images/productos-img/11/principal.jpg";
import carousel2_11 from "../assets/images/productos-img/11/carousel2.jpg";
import carousel3_11 from "../assets/images/productos-img/11/carousel3.jpg";
import carousel4_11 from "../assets/images/productos-img/11/carousel4.jpg";
import carousel5_11 from "../assets/images/productos-img/11/carousel5.jpg";
import principal_12 from "../assets/images/productos-img/12/principal.jpg";
import carousel2_12 from "../assets/images/productos-img/12/carousel2.jpg";
import carousel3_12 from "../assets/images/productos-img/12/carousel3.jpg";
import carousel4_12 from "../assets/images/productos-img/12/carousel4.jpg";
import carousel5_12 from "../assets/images/productos-img/12/carousel5.jpg";
import principal_13 from "../assets/images/productos-img/13/principal.jpg";
import carousel2_13 from "../assets/images/productos-img/13/carousel2.jpg";
import carousel3_13 from "../assets/images/productos-img/13/carousel3.jpg";
import carousel4_13 from "../assets/images/productos-img/13/carousel4.jpg";
import carousel5_13 from "../assets/images/productos-img/13/carousel5.jpg";
import principal_14 from "../assets/images/productos-img/14/principal.jpg";
import carousel2_14 from "../assets/images/productos-img/14/carousel2.jpg";
import carousel3_14 from "../assets/images/productos-img/14/carousel3.jpg";
import carousel4_14 from "../assets/images/productos-img/14/carousel4.jpg";
import carousel5_14 from "../assets/images/productos-img/14/carousel5.jpg";
import principal_15 from "../assets/images/productos-img/15/principal.jpg";
import carousel2_15 from "../assets/images/productos-img/15/carousel2.jpg";
import carousel3_15 from "../assets/images/productos-img/15/carousel3.jpg";
import carousel4_15 from "../assets/images/productos-img/15/carousel4.jpg";
import carousel5_15 from "../assets/images/productos-img/15/carousel5.jpg";
import principal_16 from "../assets/images/productos-img/16/principal.jpg";
import carousel2_16 from "../assets/images/productos-img/16/carousel2.jpg";
import carousel3_16 from "../assets/images/productos-img/16/carousel3.jpg";
import carousel4_16 from "../assets/images/productos-img/16/carousel4.jpg";
import carousel5_16 from "../assets/images/productos-img/16/carousel5.jpg";
import principal_17 from "../assets/images/productos-img/17/principal.jpg";
import carousel2_17 from "../assets/images/productos-img/17/carousel2.jpg";
import carousel3_17 from "../assets/images/productos-img/17/carousel3.jpg";
import carousel4_17 from "../assets/images/productos-img/17/carousel4.jpg";
import carousel5_17 from "../assets/images/productos-img/17/carousel5.jpg";
import principal_18 from "../assets/images/productos-img/18/principal.jpg";
import carousel2_18 from "../assets/images/productos-img/18/carousel2.jpg";
import carousel3_18 from "../assets/images/productos-img/18/carousel3.jpg";
import carousel4_18 from "../assets/images/productos-img/18/carousel4.jpg";
import carousel5_18 from "../assets/images/productos-img/18/carousel5.jpg";
import principal_19 from "../assets/images/productos-img/19/principal.jpg";
import carousel2_19 from "../assets/images/productos-img/19/carousel2.jpg";
import carousel3_19 from "../assets/images/productos-img/19/carousel3.jpg";
import carousel4_19 from "../assets/images/productos-img/19/carousel4.jpg";
import carousel5_19 from "../assets/images/productos-img/19/carousel5.jpg";
import principal_20 from "../assets/images/productos-img/20/principal.jpg";
import carousel2_20 from "../assets/images/productos-img/20/carousel2.jpg";
import carousel3_20 from "../assets/images/productos-img/20/carousel3.jpg";
import carousel4_20 from "../assets/images/productos-img/20/carousel4.jpg";
import carousel5_20 from "../assets/images/productos-img/20/carousel5.jpg";
import principal_21 from "../assets/images/productos-img/21/principal.jpg";
import carousel2_21 from "../assets/images/productos-img/21/carousel2.jpg";
import carousel3_21 from "../assets/images/productos-img/21/carousel3.jpg";
import carousel4_21 from "../assets/images/productos-img/21/carousel4.jpg";
import carousel5_21 from "../assets/images/productos-img/21/carousel5.jpg";
import principal_22 from "../assets/images/productos-img/22/principal.jpg";
import carousel2_22 from "../assets/images/productos-img/22/carousel2.jpg";
import carousel3_22 from "../assets/images/productos-img/22/carousel3.jpg";
import carousel4_22 from "../assets/images/productos-img/22/carousel4.jpg";
import carousel5_22 from "../assets/images/productos-img/22/carousel5.jpg";
import principal_23 from "../assets/images/productos-img/23/principal.jpg";
import carousel2_23 from "../assets/images/productos-img/23/carousel2.jpg";
import carousel3_23 from "../assets/images/productos-img/23/carousel3.jpg";
import carousel4_23 from "../assets/images/productos-img/23/carousel4.jpg";
import carousel5_23 from "../assets/images/productos-img/23/carousel5.jpg";
import principal_24 from "../assets/images/productos-img/24/principal.jpg";
import carousel2_24 from "../assets/images/productos-img/24/carousel2.jpg";
import carousel3_24 from "../assets/images/productos-img/24/carousel3.jpg";
import carousel4_24 from "../assets/images/productos-img/24/carousel4.jpg";
import carousel5_24 from "../assets/images/productos-img/24/carousel5.jpg";
import principal_25 from "../assets/images/productos-img/25/principal.jpg";
import carousel2_25 from "../assets/images/productos-img/25/carousel2.jpg";
import carousel3_25 from "../assets/images/productos-img/25/carousel3.jpg";
import carousel4_25 from "../assets/images/productos-img/25/carousel4.jpg";
import carousel5_25 from "../assets/images/productos-img/25/carousel5.jpg";
import principal_26 from "../assets/images/productos-img/26/principal.jpg";
import carousel2_26 from "../assets/images/productos-img/26/carousel2.jpg";
import carousel3_26 from "../assets/images/productos-img/26/carousel3.jpg";
import carousel4_26 from "../assets/images/productos-img/26/carousel4.jpg";
import carousel5_26 from "../assets/images/productos-img/26/carousel5.jpg";
import principal_27 from "../assets/images/productos-img/27/principal.jpg";
import carousel2_27 from "../assets/images/productos-img/27/carousel2.jpg";
import carousel3_27 from "../assets/images/productos-img/27/carousel3.jpg";
import carousel4_27 from "../assets/images/productos-img/27/carousel4.jpg";
import carousel5_27 from "../assets/images/productos-img/27/carousel5.jpg";
import principal_28 from "../assets/images/productos-img/28/principal.jpg";
import carousel2_28 from "../assets/images/productos-img/28/carousel2.jpg";
import carousel3_28 from "../assets/images/productos-img/28/carousel3.jpg";
import carousel4_28 from "../assets/images/productos-img/28/carousel4.jpg";
import carousel5_28 from "../assets/images/productos-img/28/carousel5.jpg";
import principal_29 from "../assets/images/productos-img/29/principal.jpg";
import carousel2_29 from "../assets/images/productos-img/29/carousel2.jpg";
import carousel3_29 from "../assets/images/productos-img/29/carousel3.jpg";
import carousel4_29 from "../assets/images/productos-img/29/carousel4.jpg";
import carousel5_29 from "../assets/images/productos-img/29/carousel5.jpg";
import principal_30 from "../assets/images/productos-img/30/principal.jpg";
import carousel2_30 from "../assets/images/productos-img/30/carousel2.jpg";
import carousel3_30 from "../assets/images/productos-img/30/carousel3.jpg";
import carousel4_30 from "../assets/images/productos-img/30/carousel4.jpg";
import carousel5_30 from "../assets/images/productos-img/30/carousel5.jpg";
import principal_31 from "../assets/images/productos-img/31/principal.jpg";
import carousel2_31 from "../assets/images/productos-img/31/carousel2.jpg";
import carousel3_31 from "../assets/images/productos-img/31/carousel3.jpg";
import carousel4_31 from "../assets/images/productos-img/31/carousel4.jpg";
import carousel5_31 from "../assets/images/productos-img/31/carousel5.jpg";
import principal_32 from "../assets/images/productos-img/32/principal.jpg";
import carousel2_32 from "../assets/images/productos-img/32/carousel2.jpg";
import carousel3_32 from "../assets/images/productos-img/32/carousel3.jpg";
import carousel4_32 from "../assets/images/productos-img/32/carousel4.jpg";
import carousel5_32 from "../assets/images/productos-img/32/carousel5.jpg";
import principal_33 from "../assets/images/productos-img/33/principal.jpg";
import carousel2_33 from "../assets/images/productos-img/33/carousel2.jpg";
import carousel3_33 from "../assets/images/productos-img/33/carousel3.jpg";
import carousel4_33 from "../assets/images/productos-img/33/carousel4.jpg";
import carousel5_33 from "../assets/images/productos-img/33/carousel5.jpg";
import principal_34 from "../assets/images/productos-img/34/principal.jpg";
import carousel2_34 from "../assets/images/productos-img/34/carousel2.jpg";
import carousel3_34 from "../assets/images/productos-img/34/carousel3.jpg";
import carousel4_34 from "../assets/images/productos-img/34/carousel4.jpg";
import carousel5_34 from "../assets/images/productos-img/34/carousel5.jpg";
import principal_35 from "../assets/images/productos-img/35/principal.jpg";
import carousel2_35 from "../assets/images/productos-img/35/carousel2.jpg";
import carousel3_35 from "../assets/images/productos-img/35/carousel3.jpg";
import carousel4_35 from "../assets/images/productos-img/35/carousel4.jpg";
import carousel5_35 from "../assets/images/productos-img/35/carousel5.jpg";
import principal_36 from "../assets/images/productos-img/36/principal.jpg";
import carousel2_36 from "../assets/images/productos-img/36/carousel2.jpg";
import carousel3_36 from "../assets/images/productos-img/36/carousel3.jpg";
import carousel4_36 from "../assets/images/productos-img/36/carousel4.jpg";
import carousel5_36 from "../assets/images/productos-img/36/carousel5.jpg";
import principal_37 from "../assets/images/productos-img/37/principal.jpg";
import carousel2_37 from "../assets/images/productos-img/37/carousel2.jpg";
import carousel3_37 from "../assets/images/productos-img/37/carousel3.jpg";
import carousel4_37 from "../assets/images/productos-img/37/carousel4.jpg";
import carousel5_37 from "../assets/images/productos-img/37/carousel5.jpg";
import principal_38 from "../assets/images/productos-img/38/principal.jpg";
import carousel2_38 from "../assets/images/productos-img/38/carousel2.jpg";
import carousel3_38 from "../assets/images/productos-img/38/carousel3.jpg";
import carousel4_38 from "../assets/images/productos-img/38/carousel4.jpg";
import carousel5_38 from "../assets/images/productos-img/38/carousel5.jpg";
import principal_39 from "../assets/images/productos-img/39/principal.jpg";
import carousel2_39 from "../assets/images/productos-img/39/carousel2.jpg";
import carousel3_39 from "../assets/images/productos-img/39/carousel3.jpg";
import carousel4_39 from "../assets/images/productos-img/39/carousel4.jpg";
import carousel5_39 from "../assets/images/productos-img/39/carousel5.jpg";
import principal_40 from "../assets/images/productos-img/40/principal.jpg";
import carousel2_40 from "../assets/images/productos-img/40/carousel2.jpg";
import carousel3_40 from "../assets/images/productos-img/40/carousel3.jpg";
import carousel4_40 from "../assets/images/productos-img/40/carousel4.jpg";
import carousel5_40 from "../assets/images/productos-img/40/carousel5.jpg";
import principal_41 from "../assets/images/productos-img/41/principal.jpg";
import carousel2_41 from "../assets/images/productos-img/41/carousel2.jpg";
import carousel3_41 from "../assets/images/productos-img/41/carousel3.jpg";
import carousel4_41 from "../assets/images/productos-img/41/carousel4.jpg";
import carousel5_41 from "../assets/images/productos-img/41/carousel5.jpg";
import principal_42 from "../assets/images/productos-img/42/principal.jpg";
import carousel2_42 from "../assets/images/productos-img/42/carousel2.jpg";
import carousel3_42 from "../assets/images/productos-img/42/carousel3.jpg";
import carousel4_42 from "../assets/images/productos-img/42/carousel4.jpg";
import carousel5_42 from "../assets/images/productos-img/42/carousel5.jpg";
import principal_43 from "../assets/images/productos-img/43/principal.jpg";
import carousel2_43 from "../assets/images/productos-img/43/carousel2.jpg";
import carousel3_43 from "../assets/images/productos-img/43/carousel3.jpg";
import carousel4_43 from "../assets/images/productos-img/43/carousel4.jpg";
import carousel5_43 from "../assets/images/productos-img/43/carousel5.jpg";
import principal_44 from "../assets/images/productos-img/44/principal.jpg";
import carousel2_44 from "../assets/images/productos-img/44/carousel2.jpg";
import carousel3_44 from "../assets/images/productos-img/44/carousel3.jpg";
import carousel4_44 from "../assets/images/productos-img/44/carousel4.jpg";
import carousel5_44 from "../assets/images/productos-img/44/carousel5.jpg";
import principal_45 from "../assets/images/productos-img/45/principal.jpg";
import carousel2_45 from "../assets/images/productos-img/45/carousel2.jpg";
import carousel3_45 from "../assets/images/productos-img/45/carousel3.jpg";
import carousel4_45 from "../assets/images/productos-img/45/carousel4.jpg";
import carousel5_45 from "../assets/images/productos-img/45/carousel5.jpg";
import principal_46 from "../assets/images/productos-img/46/principal.jpg";
import carousel2_46 from "../assets/images/productos-img/46/carousel2.jpg";
import carousel3_46 from "../assets/images/productos-img/46/carousel3.jpg";
import carousel4_46 from "../assets/images/productos-img/46/carousel4.jpg";
import carousel5_46 from "../assets/images/productos-img/46/carousel5.jpg";
import principal_47 from "../assets/images/productos-img/47/principal.jpg";
import carousel2_47 from "../assets/images/productos-img/47/carousel2.jpg";
import carousel3_47 from "../assets/images/productos-img/47/carousel3.jpg";
import carousel4_47 from "../assets/images/productos-img/47/carousel4.jpg";
import carousel5_47 from "../assets/images/productos-img/47/carousel5.jpg";
import principal_48 from "../assets/images/productos-img/48/principal.jpg";
import carousel2_48 from "../assets/images/productos-img/48/carousel2.jpg";
import carousel3_48 from "../assets/images/productos-img/48/carousel3.jpg";
import carousel4_48 from "../assets/images/productos-img/48/carousel4.jpg";
import carousel5_48 from "../assets/images/productos-img/48/carousel5.jpg";
import principal_49 from "../assets/images/productos-img/49/principal.jpg";
import carousel2_49 from "../assets/images/productos-img/49/carousel2.jpg";
import carousel3_49 from "../assets/images/productos-img/49/carousel3.jpg";
import carousel4_49 from "../assets/images/productos-img/49/carousel4.jpg";
import carousel5_49 from "../assets/images/productos-img/49/carousel5.jpg";
import principal_50 from "../assets/images/productos-img/50/principal.jpg";
import carousel2_50 from "../assets/images/productos-img/50/carousel2.jpg";
import carousel3_50 from "../assets/images/productos-img/50/carousel3.jpg";
import carousel4_50 from "../assets/images/productos-img/50/carousel4.jpg";
import carousel5_50 from "../assets/images/productos-img/50/carousel5.jpg";
import principal_51 from "../assets/images/productos-img/51/principal.jpg";
import carousel2_51 from "../assets/images/productos-img/51/carousel2.jpg";
import principal_100 from "../assets/images/productos-img/100/principal.jpg";
import carousel2_100 from "../assets/images/productos-img/100/carousel2.jpg";
import carousel3_100 from "../assets/images/productos-img/100/carousel3.jpg";
import carousel4_100 from "../assets/images/productos-img/100/carousel4.jpg";
import carousel5_100 from "../assets/images/productos-img/100/carousel5.jpg";
import principal_101 from "../assets/images/productos-img/101/principal.jpg";
import carousel2_101 from "../assets/images/productos-img/101/carousel2.jpg";
import carousel3_101 from "../assets/images/productos-img/101/carousel3.jpg";
import carousel4_101 from "../assets/images/productos-img/101/carousel4.jpg";
import carousel5_101 from "../assets/images/productos-img/101/carousel5.jpg";
import principal_102 from "../assets/images/productos-img/102/principal.jpg";
import carousel2_102 from "../assets/images/productos-img/102/carousel2.jpg";
import carousel3_102 from "../assets/images/productos-img/102/carousel3.jpg";
import carousel4_102 from "../assets/images/productos-img/102/carousel4.jpg";
import carousel5_102 from "../assets/images/productos-img/102/carousel5.jpg";
import principal_103 from "../assets/images/productos-img/103/principal.jpg";
import carousel2_103 from "../assets/images/productos-img/103/carousel2.jpg";
import carousel3_103 from "../assets/images/productos-img/103/carousel3.jpg";
import carousel4_103 from "../assets/images/productos-img/103/carousel4.jpg";
import carousel5_103 from "../assets/images/productos-img/103/carousel5.jpg";
import principal_104 from "../assets/images/productos-img/104/principal.jpg";
import carousel2_104 from "../assets/images/productos-img/104/carousel2.jpg";
import carousel3_104 from "../assets/images/productos-img/104/carousel3.jpg";
import carousel4_104 from "../assets/images/productos-img/104/carousel4.jpg";
import carousel5_104 from "../assets/images/productos-img/104/carousel5.jpg";
import principal_105 from "../assets/images/productos-img/105/principal.jpg";
import carousel2_105 from "../assets/images/productos-img/105/carousel2.jpg";
import principal_106 from "../assets/images/productos-img/106/principal.jpg";
import carousel2_106 from "../assets/images/productos-img/106/carousel2.jpg";
import carousel3_106 from "../assets/images/productos-img/106/carousel3.jpg";
import carousel4_106 from "../assets/images/productos-img/106/carousel4.jpg";
import carousel5_106 from "../assets/images/productos-img/106/carousel5.jpg";
import principal_107 from "../assets/images/productos-img/107/principal.jpg";
import carousel2_107 from "../assets/images/productos-img/107/carousel2.jpg";
import carousel3_107 from "../assets/images/productos-img/107/carousel3.jpg";
import carousel4_107 from "../assets/images/productos-img/107/carousel4.jpg";
import carousel5_107 from "../assets/images/productos-img/107/carousel5.jpg";
import principal_108 from "../assets/images/productos-img/108/principal.jpg";
import carousel2_108 from "../assets/images/productos-img/108/carousel2.jpg";
import carousel3_108 from "../assets/images/productos-img/108/carousel3.jpg";
import carousel4_108 from "../assets/images/productos-img/108/carousel4.jpg";
import carousel5_108 from "../assets/images/productos-img/108/carousel5.jpg";
import principal_109 from "../assets/images/productos-img/109/principal.jpg";
import carousel2_109 from "../assets/images/productos-img/109/carousel2.jpg";
import carousel3_109 from "../assets/images/productos-img/109/carousel3.jpg";
import carousel4_109 from "../assets/images/productos-img/109/carousel4.jpg";
import carousel5_109 from "../assets/images/productos-img/109/carousel5.jpg";
import principal_110 from "../assets/images/productos-img/110/principal.jpg";
import carousel2_110 from "../assets/images/productos-img/110/carousel2.jpg";
import carousel3_110 from "../assets/images/productos-img/110/carousel3.jpg";
import carousel4_110 from "../assets/images/productos-img/110/carousel4.jpg";
import carousel5_110 from "../assets/images/productos-img/110/carousel5.jpg";
import principal_111 from "../assets/images/productos-img/111/principal.jpg";
import carousel2_111 from "../assets/images/productos-img/111/carousel2.jpg";
import carousel3_111 from "../assets/images/productos-img/111/carousel3.jpg";
import carousel4_111 from "../assets/images/productos-img/111/carousel4.jpg";
import carousel5_111 from "../assets/images/productos-img/111/carousel5.jpg";
import principal_112 from "../assets/images/productos-img/112/principal.jpg";
import carousel2_112 from "../assets/images/productos-img/112/carousel2.jpg";
import carousel3_112 from "../assets/images/productos-img/112/carousel3.jpg";
import carousel4_112 from "../assets/images/productos-img/112/carousel4.jpg";
import carousel5_112 from "../assets/images/productos-img/112/carousel5.jpg";
import principal_113 from "../assets/images/productos-img/113/principal.jpg";
import carousel2_113 from "../assets/images/productos-img/113/carousel2.jpg";
import carousel3_113 from "../assets/images/productos-img/113/carousel3.jpg";
import carousel4_113 from "../assets/images/productos-img/113/carousel4.jpg";
import carousel5_113 from "../assets/images/productos-img/113/carousel5.jpg";
import principal_114 from "../assets/images/productos-img/114/principal.jpg";
import carousel2_114 from "../assets/images/productos-img/114/carousel2.jpg";
import carousel3_114 from "../assets/images/productos-img/114/carousel3.jpg";
import carousel4_114 from "../assets/images/productos-img/114/carousel4.jpg";
import carousel5_114 from "../assets/images/productos-img/114/carousel5.jpg";
import principal_115 from "../assets/images/productos-img/115/principal.jpg";
import carousel2_115 from "../assets/images/productos-img/115/carousel2.jpg";
import carousel3_115 from "../assets/images/productos-img/115/carousel3.jpg";
import carousel4_115 from "../assets/images/productos-img/115/carousel4.jpg";
import carousel5_115 from "../assets/images/productos-img/115/carousel5.jpg";
import principal_116 from "../assets/images/productos-img/116/principal.jpg";
import carousel2_116 from "../assets/images/productos-img/116/carousel2.jpg";
import carousel3_116 from "../assets/images/productos-img/116/carousel3.jpg";
import carousel4_116 from "../assets/images/productos-img/116/carousel4.jpg";
import carousel5_116 from "../assets/images/productos-img/116/carousel5.jpg";
import principal_117 from "../assets/images/productos-img/117/principal.jpg";
import carousel2_117 from "../assets/images/productos-img/117/carousel2.jpg";
import carousel3_117 from "../assets/images/productos-img/117/carousel3.jpg";
import carousel4_117 from "../assets/images/productos-img/117/carousel4.jpg";
import carousel5_117 from "../assets/images/productos-img/117/carousel5.jpg";
import principal_118 from "../assets/images/productos-img/118/principal.jpg";
import carousel2_118 from "../assets/images/productos-img/118/carousel2.jpg";
import carousel3_118 from "../assets/images/productos-img/118/carousel3.jpg";
import carousel4_118 from "../assets/images/productos-img/118/carousel4.jpg";
import carousel5_118 from "../assets/images/productos-img/118/carousel5.jpg";
import principal_119 from "../assets/images/productos-img/119/principal.jpg";
import carousel2_119 from "../assets/images/productos-img/119/carousel2.jpg";
import carousel3_119 from "../assets/images/productos-img/119/carousel3.jpg";
import carousel4_119 from "../assets/images/productos-img/119/carousel4.jpg";
import carousel5_119 from "../assets/images/productos-img/119/carousel5.jpg";
import principal_120 from "../assets/images/productos-img/120/principal.jpg";
import carousel2_120 from "../assets/images/productos-img/120/carousel2.jpg";
import carousel3_120 from "../assets/images/productos-img/120/carousel3.jpg";
import carousel4_120 from "../assets/images/productos-img/120/carousel4.jpg";
import carousel5_120 from "../assets/images/productos-img/120/carousel5.jpg";
import principal_121 from "../assets/images/productos-img/121/principal.jpg";
import carousel2_121 from "../assets/images/productos-img/121/carousel2.jpg";
import carousel3_121 from "../assets/images/productos-img/121/carousel3.jpg";
import carousel4_121 from "../assets/images/productos-img/121/carousel4.jpg";
import carousel5_121 from "../assets/images/productos-img/121/carousel5.jpg";
import principal_122 from "../assets/images/productos-img/122/principal.jpg";
import carousel2_122 from "../assets/images/productos-img/122/carousel2.jpg";
import carousel3_122 from "../assets/images/productos-img/122/carousel3.jpg";
import carousel4_122 from "../assets/images/productos-img/122/carousel4.jpg";
import carousel5_122 from "../assets/images/productos-img/122/carousel5.jpg";
import principal_123 from "../assets/images/productos-img/123/principal.jpg";
import carousel2_123 from "../assets/images/productos-img/123/carousel2.jpg";
import carousel3_123 from "../assets/images/productos-img/123/carousel3.jpg";
import carousel4_123 from "../assets/images/productos-img/123/carousel4.jpg";
import carousel5_123 from "../assets/images/productos-img/123/carousel5.jpg";
import principal_124 from "../assets/images/productos-img/124/principal.jpg";
import carousel2_124 from "../assets/images/productos-img/124/carousel2.jpg";
import carousel3_124 from "../assets/images/productos-img/124/carousel3.jpg";
import carousel4_124 from "../assets/images/productos-img/124/carousel4.jpg";
import carousel5_124 from "../assets/images/productos-img/124/carousel5.jpg";
import principal_125 from "../assets/images/productos-img/125/principal.jpg";
import carousel2_125 from "../assets/images/productos-img/125/carousel2.jpg";
import carousel3_125 from "../assets/images/productos-img/125/carousel3.jpg";
import carousel4_125 from "../assets/images/productos-img/125/carousel4.jpg";
import carousel5_125 from "../assets/images/productos-img/125/carousel5.jpg";
import principal_126 from "../assets/images/productos-img/126/principal.jpg";
import carousel2_126 from "../assets/images/productos-img/126/carousel2.jpg";
import carousel3_126 from "../assets/images/productos-img/126/carousel3.jpg";
import carousel4_126 from "../assets/images/productos-img/126/carousel4.jpg";
import carousel5_126 from "../assets/images/productos-img/126/carousel5.jpg";
import principal_127 from "../assets/images/productos-img/127/principal.jpg";
import principal_128 from "../assets/images/productos-img/128/principal.jpg";
import carousel2_128 from "../assets/images/productos-img/128/carousel2.jpg";
import carousel3_128 from "../assets/images/productos-img/128/carousel3.jpg";
import carousel4_128 from "../assets/images/productos-img/128/carousel4.jpg";
import carousel5_128 from "../assets/images/productos-img/128/carousel5.jpg";
import principal_129 from "../assets/images/productos-img/129/principal.jpg";
import carousel2_129 from "../assets/images/productos-img/129/carousel2.jpg";
import carousel3_129 from "../assets/images/productos-img/129/carousel3.jpg";
import carousel4_129 from "../assets/images/productos-img/129/carousel4.jpg";
import carousel5_129 from "../assets/images/productos-img/129/carousel5.jpg";
import principal_130 from "../assets/images/productos-img/130/principal.jpg";
import carousel2_130 from "../assets/images/productos-img/130/carousel2.jpg";
import carousel3_130 from "../assets/images/productos-img/130/carousel3.jpg";
import carousel4_130 from "../assets/images/productos-img/130/carousel4.jpg";
import carousel5_130 from "../assets/images/productos-img/130/carousel5.jpg";
import principal_131 from "../assets/images/productos-img/131/principal.jpg";
import carousel2_131 from "../assets/images/productos-img/131/carousel2.jpg";
import carousel3_131 from "../assets/images/productos-img/131/carousel3.jpg";
import principal_132 from "../assets/images/productos-img/132/principal.jpg";
import carousel2_132 from "../assets/images/productos-img/132/carousel2.jpg";
import carousel3_132 from "../assets/images/productos-img/132/carousel3.jpg";
import carousel4_132 from "../assets/images/productos-img/132/carousel4.jpg";
import carousel5_132 from "../assets/images/productos-img/132/carousel5.jpg";
import principal_200 from "../assets/images/productos-img/200/principal.jpg";
import carousel2_200 from "../assets/images/productos-img/200/carousel2.jpg";
import carousel3_200 from "../assets/images/productos-img/200/carousel3.jpg";
import carousel4_200 from "../assets/images/productos-img/200/carousel4.jpg";
import carousel5_200 from "../assets/images/productos-img/200/carousel5.jpg";
import principal_201 from "../assets/images/productos-img/201/principal.jpg";
import carousel2_201 from "../assets/images/productos-img/201/carousel2.jpg";
import carousel3_201 from "../assets/images/productos-img/201/carousel3.jpg";
import carousel4_201 from "../assets/images/productos-img/201/carousel4.jpg";
import carousel5_201 from "../assets/images/productos-img/201/carousel5.jpg";
import principal_202 from "../assets/images/productos-img/202/principal.jpg";
import carousel2_202 from "../assets/images/productos-img/202/carousel2.jpg";
import carousel3_202 from "../assets/images/productos-img/202/carousel3.jpg";
import carousel4_202 from "../assets/images/productos-img/202/carousel4.jpg";
import carousel5_202 from "../assets/images/productos-img/202/carousel5.jpg";
import principal_203 from "../assets/images/productos-img/203/principal.jpg";
import carousel2_203 from "../assets/images/productos-img/203/carousel2.jpg";
import carousel3_203 from "../assets/images/productos-img/203/carousel3.jpg";
import carousel4_203 from "../assets/images/productos-img/203/carousel4.jpg";
import carousel5_203 from "../assets/images/productos-img/203/carousel5.jpg";
import principal_204 from "../assets/images/productos-img/204/principal.jpg";
import carousel2_204 from "../assets/images/productos-img/204/carousel2.jpg";
import carousel3_204 from "../assets/images/productos-img/204/carousel3.jpg";
import carousel4_204 from "../assets/images/productos-img/204/carousel4.jpg";
import carousel5_204 from "../assets/images/productos-img/204/carousel5.jpg";
import principal_205 from "../assets/images/productos-img/205/principal.jpg";
import carousel2_205 from "../assets/images/productos-img/205/carousel2.jpg";
import carousel3_205 from "../assets/images/productos-img/205/carousel3.jpg";
import carousel4_205 from "../assets/images/productos-img/205/carousel4.jpg";
import carousel5_205 from "../assets/images/productos-img/205/carousel5.jpg";
import principal_206 from "../assets/images/productos-img/206/principal.jpg";
import carousel2_206 from "../assets/images/productos-img/206/carousel2.jpg";
import carousel3_206 from "../assets/images/productos-img/206/carousel3.jpg";
import carousel4_206 from "../assets/images/productos-img/206/carousel4.jpg";
import carousel5_206 from "../assets/images/productos-img/206/carousel5.jpg";
import principal_207 from "../assets/images/productos-img/207/principal.jpg";
import carousel2_207 from "../assets/images/productos-img/207/carousel2.jpg";
import carousel3_207 from "../assets/images/productos-img/207/carousel3.jpg";
import carousel4_207 from "../assets/images/productos-img/207/carousel4.jpg";
import carousel5_207 from "../assets/images/productos-img/207/carousel5.jpg";
import principal_208 from "../assets/images/productos-img/208/principal.jpg";
import carousel2_208 from "../assets/images/productos-img/208/carousel2.jpg";
import carousel3_208 from "../assets/images/productos-img/208/carousel3.jpg";
import carousel4_208 from "../assets/images/productos-img/208/carousel4.jpg";
import carousel5_208 from "../assets/images/productos-img/208/carousel5.jpg";
import principal_209 from "../assets/images/productos-img/209/principal.jpg";
import carousel2_209 from "../assets/images/productos-img/209/carousel2.jpg";
import carousel3_209 from "../assets/images/productos-img/209/carousel3.jpg";
import carousel4_209 from "../assets/images/productos-img/209/carousel4.jpg";
import carousel5_209 from "../assets/images/productos-img/209/carousel5.jpg";
import principal_210 from "../assets/images/productos-img/210/principal.jpg";
import carousel2_210 from "../assets/images/productos-img/210/carousel2.jpg";
import carousel3_210 from "../assets/images/productos-img/210/carousel3.jpg";
import carousel4_210 from "../assets/images/productos-img/210/carousel4.jpg";
import carousel5_210 from "../assets/images/productos-img/210/carousel5.jpg";
import principal_211 from "../assets/images/productos-img/211/principal.jpg";
import carousel2_211 from "../assets/images/productos-img/211/carousel2.jpg";
import carousel3_211 from "../assets/images/productos-img/211/carousel3.jpg";
import carousel4_211 from "../assets/images/productos-img/211/carousel4.jpg";
import carousel5_211 from "../assets/images/productos-img/211/carousel5.jpg";
import principal_212 from "../assets/images/productos-img/212/principal.jpg";
import carousel2_212 from "../assets/images/productos-img/212/carousel2.jpg";
import carousel3_212 from "../assets/images/productos-img/212/carousel3.jpg";
import carousel4_212 from "../assets/images/productos-img/212/carousel4.jpg";
import carousel5_212 from "../assets/images/productos-img/212/carousel5.jpg";
import principal_213 from "../assets/images/productos-img/213/principal.jpg";
import carousel2_213 from "../assets/images/productos-img/213/carousel2.jpg";
import carousel3_213 from "../assets/images/productos-img/213/carousel3.jpg";
import carousel4_213 from "../assets/images/productos-img/213/carousel4.jpg";
import carousel5_213 from "../assets/images/productos-img/213/carousel5.jpg";
import principal_214 from "../assets/images/productos-img/214/principal.jpg";
import carousel2_214 from "../assets/images/productos-img/214/carousel2.jpg";
import carousel3_214 from "../assets/images/productos-img/214/carousel3.jpg";
import carousel4_214 from "../assets/images/productos-img/214/carousel4.jpg";
import carousel5_214 from "../assets/images/productos-img/214/carousel5.jpg";
import principal_215 from "../assets/images/productos-img/215/principal.jpg";
import carousel2_215 from "../assets/images/productos-img/215/carousel2.jpg";
import carousel3_215 from "../assets/images/productos-img/215/carousel3.jpg";
import carousel4_215 from "../assets/images/productos-img/215/carousel4.jpg";
import carousel5_215 from "../assets/images/productos-img/215/carousel5.jpg";
import principal_216 from "../assets/images/productos-img/216/principal.jpg";
import carousel2_216 from "../assets/images/productos-img/216/carousel2.jpg";
import carousel3_216 from "../assets/images/productos-img/216/carousel3.jpg";
import carousel4_216 from "../assets/images/productos-img/216/carousel4.jpg";
import carousel5_216 from "../assets/images/productos-img/216/carousel5.jpg";
import principal_217 from "../assets/images/productos-img/217/principal.jpg";
import carousel2_217 from "../assets/images/productos-img/217/carousel2.jpg";
import carousel3_217 from "../assets/images/productos-img/217/carousel3.jpg";
import carousel4_217 from "../assets/images/productos-img/217/carousel4.jpg";
import carousel5_217 from "../assets/images/productos-img/217/carousel5.jpg";
import principal_218 from "../assets/images/productos-img/218/principal.jpg";
import carousel2_218 from "../assets/images/productos-img/218/carousel2.jpg";
import carousel3_218 from "../assets/images/productos-img/218/carousel3.jpg";
import carousel4_218 from "../assets/images/productos-img/218/carousel4.jpg";
import carousel5_218 from "../assets/images/productos-img/218/carousel5.jpg";
import principal_219 from "../assets/images/productos-img/219/principal.jpg";
import carousel2_219 from "../assets/images/productos-img/219/carousel2.jpg";
import carousel3_219 from "../assets/images/productos-img/219/carousel3.jpg";
import carousel4_219 from "../assets/images/productos-img/219/carousel4.jpg";
import carousel5_219 from "../assets/images/productos-img/219/carousel5.jpg";
import principal_220 from "../assets/images/productos-img/220/principal.jpg";
import carousel2_220 from "../assets/images/productos-img/220/carousel2.jpg";
import carousel3_220 from "../assets/images/productos-img/220/carousel3.jpg";
import carousel4_220 from "../assets/images/productos-img/220/carousel4.jpg";
import carousel5_220 from "../assets/images/productos-img/220/carousel5.jpg";
import principal_221 from "../assets/images/productos-img/221/principal.jpg";
import carousel2_221 from "../assets/images/productos-img/221/carousel2.jpg";
import carousel3_221 from "../assets/images/productos-img/221/carousel3.jpg";
import carousel4_221 from "../assets/images/productos-img/221/carousel4.jpg";
import carousel5_221 from "../assets/images/productos-img/221/carousel5.jpg";
import principal_222 from "../assets/images/productos-img/222/principal.jpg";
import carousel2_222 from "../assets/images/productos-img/222/carousel2.jpg";
import carousel3_222 from "../assets/images/productos-img/222/carousel3.jpg";
import carousel4_222 from "../assets/images/productos-img/222/carousel4.jpg";
import carousel5_222 from "../assets/images/productos-img/222/carousel5.jpg";
import principal_223 from "../assets/images/productos-img/223/principal.jpg";
import carousel2_223 from "../assets/images/productos-img/223/carousel2.jpg";
import carousel3_223 from "../assets/images/productos-img/223/carousel3.jpg";
import carousel4_223 from "../assets/images/productos-img/223/carousel4.jpg";
import carousel5_223 from "../assets/images/productos-img/223/carousel5.jpg";
import principal_224 from "../assets/images/productos-img/224/principal.jpg";
import carousel2_224 from "../assets/images/productos-img/224/carousel2.jpg";
import carousel3_224 from "../assets/images/productos-img/224/carousel3.jpg";
import carousel4_224 from "../assets/images/productos-img/224/carousel4.jpg";
import carousel5_224 from "../assets/images/productos-img/224/carousel5.jpg";
import principal_225 from "../assets/images/productos-img/225/principal.jpg";
import carousel2_225 from "../assets/images/productos-img/225/carousel2.jpg";
import carousel3_225 from "../assets/images/productos-img/225/carousel3.jpg";
import carousel4_225 from "../assets/images/productos-img/225/carousel4.jpg";
import carousel5_225 from "../assets/images/productos-img/225/carousel5.jpg";
import principal_226 from "../assets/images/productos-img/226/principal.jpg";
import carousel2_226 from "../assets/images/productos-img/226/carousel2.jpg";
import carousel3_226 from "../assets/images/productos-img/226/carousel3.jpg";
import carousel4_226 from "../assets/images/productos-img/226/carousel4.jpg";
import carousel5_226 from "../assets/images/productos-img/226/carousel5.jpg";
import principal_227 from "../assets/images/productos-img/227/principal.jpg";
import carousel2_227 from "../assets/images/productos-img/227/carousel2.jpg";
import carousel3_227 from "../assets/images/productos-img/227/carousel3.jpg";
import carousel4_227 from "../assets/images/productos-img/227/carousel4.jpg";
import carousel5_227 from "../assets/images/productos-img/227/carousel5.jpg";
import principal_228 from "../assets/images/productos-img/228/principal.jpg";
import carousel2_228 from "../assets/images/productos-img/228/carousel2.jpg";
import carousel3_228 from "../assets/images/productos-img/228/carousel3.jpg";
import carousel4_228 from "../assets/images/productos-img/228/carousel4.jpg";
import carousel5_228 from "../assets/images/productos-img/228/carousel5.jpg";
import principal_229 from "../assets/images/productos-img/229/principal.jpg";
import carousel2_229 from "../assets/images/productos-img/229/carousel2.jpg";
import carousel3_229 from "../assets/images/productos-img/229/carousel3.jpg";
import carousel4_229 from "../assets/images/productos-img/229/carousel4.jpg";
import carousel5_229 from "../assets/images/productos-img/229/carousel5.jpg";
import principal_230 from "../assets/images/productos-img/230/principal.jpg";
import carousel2_230 from "../assets/images/productos-img/230/carousel2.jpg";
import carousel3_230 from "../assets/images/productos-img/230/carousel3.jpg";
import principal_231 from "../assets/images/productos-img/231/principal.jpg";
import carousel2_231 from "../assets/images/productos-img/231/carousel2.jpg";
import carousel3_231 from "../assets/images/productos-img/231/carousel3.jpg";
import carousel4_231 from "../assets/images/productos-img/231/carousel4.jpg";
import principal_300 from "../assets/images/productos-img/300/principal.jpg";
import carousel2_300 from "../assets/images/productos-img/300/carousel2.jpg";
import carousel3_300 from "../assets/images/productos-img/300/carousel3.jpg";
import carousel4_300 from "../assets/images/productos-img/300/carousel4.jpg";
import carousel5_300 from "../assets/images/productos-img/300/carousel5.jpg";
import principal_301 from "../assets/images/productos-img/301/principal.jpg";
import carousel2_301 from "../assets/images/productos-img/301/carousel2.jpg";
import carousel3_301 from "../assets/images/productos-img/301/carousel3.jpg";
import carousel4_301 from "../assets/images/productos-img/301/carousel4.jpg";
import carousel5_301 from "../assets/images/productos-img/301/carousel5.jpg";
import principal_302 from "../assets/images/productos-img/302/principal.jpg";
import carousel2_302 from "../assets/images/productos-img/302/carousel2.jpg";
import carousel3_302 from "../assets/images/productos-img/302/carousel3.jpg";
import carousel4_302 from "../assets/images/productos-img/302/carousel4.jpg";
import carousel5_302 from "../assets/images/productos-img/302/carousel5.jpg";
import principal_303 from "../assets/images/productos-img/303/principal.jpg";
import carousel2_303 from "../assets/images/productos-img/303/carousel2.jpg";
import carousel3_303 from "../assets/images/productos-img/303/carousel3.jpg";
import carousel4_303 from "../assets/images/productos-img/303/carousel4.jpg";
import carousel5_303 from "../assets/images/productos-img/303/carousel5.jpg";
import principal_304 from "../assets/images/productos-img/304/principal.jpg";
import carousel2_304 from "../assets/images/productos-img/304/carousel2.jpg";
import carousel3_304 from "../assets/images/productos-img/304/carousel3.jpg";
import carousel4_304 from "../assets/images/productos-img/304/carousel4.jpg";
import carousel5_304 from "../assets/images/productos-img/304/carousel5.jpg";
import principal_305 from "../assets/images/productos-img/305/principal.jpg";
import carousel2_305 from "../assets/images/productos-img/305/carousel2.jpg";
import carousel3_305 from "../assets/images/productos-img/305/carousel3.jpg";
import carousel4_305 from "../assets/images/productos-img/305/carousel4.jpg";
import carousel5_305 from "../assets/images/productos-img/305/carousel5.jpg";
import principal_306 from "../assets/images/productos-img/306/principal.jpg";
import carousel2_306 from "../assets/images/productos-img/306/carousel2.jpg";
import carousel3_306 from "../assets/images/productos-img/306/carousel3.jpg";
import carousel4_306 from "../assets/images/productos-img/306/carousel4.jpg";
import carousel5_306 from "../assets/images/productos-img/306/carousel5.jpg";
import principal_307 from "../assets/images/productos-img/307/principal.jpg";
import carousel2_307 from "../assets/images/productos-img/307/carousel2.jpg";
import carousel3_307 from "../assets/images/productos-img/307/carousel3.jpg";
import carousel4_307 from "../assets/images/productos-img/307/carousel4.jpg";
import carousel5_307 from "../assets/images/productos-img/307/carousel5.jpg";
import principal_308 from "../assets/images/productos-img/308/principal.jpg";
import carousel2_308 from "../assets/images/productos-img/308/carousel2.jpg";
import carousel3_308 from "../assets/images/productos-img/308/carousel3.jpg";
import carousel4_308 from "../assets/images/productos-img/308/carousel4.jpg";
import carousel5_308 from "../assets/images/productos-img/308/carousel5.jpg";
import principal_400 from "../assets/images/productos-img/400/principal.jpg";
import carousel2_400 from "../assets/images/productos-img/400/carousel2.jpg";
import carousel3_400 from "../assets/images/productos-img/400/carousel3.jpg";
import carousel4_400 from "../assets/images/productos-img/400/carousel4.jpg";
import carousel5_400 from "../assets/images/productos-img/400/carousel5.jpg";
import principal_401 from "../assets/images/productos-img/401/principal.jpg";
import carousel2_401 from "../assets/images/productos-img/401/carousel2.jpg";
import carousel3_401 from "../assets/images/productos-img/401/carousel3.jpg";
import carousel4_401 from "../assets/images/productos-img/401/carousel4.jpg";
import carousel5_401 from "../assets/images/productos-img/401/carousel5.jpg";
import principal_402 from "../assets/images/productos-img/402/principal.jpg";
import carousel2_402 from "../assets/images/productos-img/402/carousel2.jpg";
import carousel3_402 from "../assets/images/productos-img/402/carousel3.jpg";
import carousel4_402 from "../assets/images/productos-img/402/carousel4.jpg";
import carousel5_402 from "../assets/images/productos-img/402/carousel5.jpg";
import principal_403 from "../assets/images/productos-img/403/principal.jpg";
import carousel2_403 from "../assets/images/productos-img/403/carousel2.jpg";
import carousel3_403 from "../assets/images/productos-img/403/carousel3.jpg";
import carousel4_403 from "../assets/images/productos-img/403/carousel4.jpg";
import carousel5_403 from "../assets/images/productos-img/403/carousel5.jpg";
import principal_404 from "../assets/images/productos-img/404/principal.jpg";
import carousel2_404 from "../assets/images/productos-img/404/carousel2.jpg";
import carousel3_404 from "../assets/images/productos-img/404/carousel3.jpg";
import carousel4_404 from "../assets/images/productos-img/404/carousel4.jpg";
import carousel5_404 from "../assets/images/productos-img/404/carousel5.jpg";
import principal_405 from "../assets/images/productos-img/405/principal.jpg";
import carousel2_405 from "../assets/images/productos-img/405/carousel2.jpg";
import carousel3_405 from "../assets/images/productos-img/405/carousel3.jpg";
import carousel4_405 from "../assets/images/productos-img/405/carousel4.jpg";
import carousel5_405 from "../assets/images/productos-img/405/carousel5.jpg";
import principal_406 from "../assets/images/productos-img/406/principal.jpg";
import carousel2_406 from "../assets/images/productos-img/406/carousel2.jpg";
import carousel3_406 from "../assets/images/productos-img/406/carousel3.jpg";
import carousel4_406 from "../assets/images/productos-img/406/carousel4.jpg";
import carousel5_406 from "../assets/images/productos-img/406/carousel5.jpg";
import principal_407 from "../assets/images/productos-img/407/principal.jpg";
import carousel2_407 from "../assets/images/productos-img/407/carousel2.jpg";
import carousel3_407 from "../assets/images/productos-img/407/carousel3.jpg";
import principal_408 from "../assets/images/productos-img/408/principal.jpg";
import carousel2_408 from "../assets/images/productos-img/408/carousel2.jpg";
import carousel3_408 from "../assets/images/productos-img/408/carousel3.jpg";
import carousel4_408 from "../assets/images/productos-img/408/carousel4.jpg";
import carousel5_408 from "../assets/images/productos-img/408/carousel5.jpg";
import principal_409 from "../assets/images/productos-img/409/principal.jpg";
import carousel2_409 from "../assets/images/productos-img/409/carousel2.jpg";
import carousel3_409 from "../assets/images/productos-img/409/carousel3.jpg";
import carousel4_409 from "../assets/images/productos-img/409/carousel4.jpg";
import carousel5_409 from "../assets/images/productos-img/409/carousel5.jpg";
import principal_410 from "../assets/images/productos-img/410/principal.jpg";
import carousel2_410 from "../assets/images/productos-img/410/carousel2.jpg";
import carousel3_410 from "../assets/images/productos-img/410/carousel3.jpg";
import carousel4_410 from "../assets/images/productos-img/410/carousel4.jpg";
import carousel5_410 from "../assets/images/productos-img/410/carousel5.jpg";
import principal_blank from "../assets/images/productos-img/blank/principal.jpg";
import carousel2_blank from "../assets/images/productos-img/blank/carousel2.jpg";
import carousel3_blank from "../assets/images/productos-img/blank/carousel3.jpg";
import carousel4_blank from "../assets/images/productos-img/blank/carousel4.jpg";
import carousel5_blank from "../assets/images/productos-img/blank/carousel5.jpg";
import principal_413 from "../assets/images/productos-img/413/principal.jpg";
import carousel2_413 from "../assets/images/productos-img/413/carousel2.jpg";
import carousel3_413 from "../assets/images/productos-img/413/carousel3.jpg";
import carousel4_413 from "../assets/images/productos-img/413/carousel4.jpg";
import carousel5_413 from "../assets/images/productos-img/413/carousel5.jpg";
import principal_414 from "../assets/images/productos-img/414/principal.jpg";
import carousel2_414 from "../assets/images/productos-img/414/carousel2.jpg";
import carousel3_414 from "../assets/images/productos-img/414/carousel3.jpg";
import carousel4_414 from "../assets/images/productos-img/414/carousel4.jpg";
import carousel5_414 from "../assets/images/productos-img/414/carousel5.jpg";
import principal_415 from "../assets/images/productos-img/415/principal.jpg";
import carousel2_415 from "../assets/images/productos-img/415/carousel2.jpg";
import carousel3_415 from "../assets/images/productos-img/415/carousel3.jpg";
import carousel4_415 from "../assets/images/productos-img/415/carousel4.jpg";
import carousel5_415 from "../assets/images/productos-img/415/carousel5.jpg";
import principal_450 from "../assets/images/productos-img/450/principal.jpg";
import carousel2_450 from "../assets/images/productos-img/450/carousel2.jpg";
import carousel3_450 from "../assets/images/productos-img/450/carousel3.jpg";
import carousel4_450 from "../assets/images/productos-img/450/carousel4.jpg";
import carousel5_450 from "../assets/images/productos-img/450/carousel5.jpg";
import principal_451 from "../assets/images/productos-img/451/principal.jpg";
import carousel2_451 from "../assets/images/productos-img/451/carousel2.jpg";
import carousel3_451 from "../assets/images/productos-img/451/carousel3.jpg";
import carousel4_451 from "../assets/images/productos-img/451/carousel4.jpg";
import carousel5_451 from "../assets/images/productos-img/451/carousel5.jpg";
import principal_452 from "../assets/images/productos-img/452/principal.jpg";
import carousel2_452 from "../assets/images/productos-img/452/carousel2.jpg";
import carousel3_452 from "../assets/images/productos-img/452/carousel3.jpg";
import carousel4_452 from "../assets/images/productos-img/452/carousel4.jpg";
import carousel5_452 from "../assets/images/productos-img/452/carousel5.jpg";
import principal_453 from "../assets/images/productos-img/453/principal.jpg";
import carousel2_453 from "../assets/images/productos-img/453/carousel2.jpg";
import carousel3_453 from "../assets/images/productos-img/453/carousel3.jpg";
import carousel4_453 from "../assets/images/productos-img/453/carousel4.jpg";
import carousel5_453 from "../assets/images/productos-img/453/carousel5.jpg";
import principal_454 from "../assets/images/productos-img/454/principal.jpg";
import carousel2_454 from "../assets/images/productos-img/454/carousel2.jpg";
import carousel3_454 from "../assets/images/productos-img/454/carousel3.jpg";
import carousel4_454 from "../assets/images/productos-img/454/carousel4.jpg";
import carousel5_454 from "../assets/images/productos-img/454/carousel5.jpg";
import principal_455 from "../assets/images/productos-img/455/principal.jpg";
import carousel2_455 from "../assets/images/productos-img/455/carousel2.jpg";
import carousel3_455 from "../assets/images/productos-img/455/carousel3.jpg";
import carousel4_455 from "../assets/images/productos-img/455/carousel4.jpg";
import carousel5_455 from "../assets/images/productos-img/455/carousel5.jpg";
import principal_456 from "../assets/images/productos-img/456/principal.jpg";
import carousel2_456 from "../assets/images/productos-img/456/carousel2.jpg";
import carousel3_456 from "../assets/images/productos-img/456/carousel3.jpg";
import principal_457 from "../assets/images/productos-img/457/principal.jpg";
import carousel2_457 from "../assets/images/productos-img/457/carousel2.jpg";
import carousel3_457 from "../assets/images/productos-img/457/carousel3.jpg";
import carousel4_457 from "../assets/images/productos-img/457/carousel4.jpg";
import carousel5_457 from "../assets/images/productos-img/457/carousel5.jpg";
import principal_460 from "../assets/images/productos-img/460/principal.jpg";
import carousel2_460 from "../assets/images/productos-img/460/carousel2.jpg";
import carousel3_460 from "../assets/images/productos-img/460/carousel3.jpg";
import carousel4_460 from "../assets/images/productos-img/460/carousel4.jpg";
import carousel5_460 from "../assets/images/productos-img/460/carousel5.jpg";
import principal_463 from "../assets/images/productos-img/463/principal.jpg";
import carousel2_463 from "../assets/images/productos-img/463/carousel2.jpg";
import carousel3_463 from "../assets/images/productos-img/463/carousel3.jpg";
import carousel4_463 from "../assets/images/productos-img/463/carousel4.jpg";
import carousel5_463 from "../assets/images/productos-img/463/carousel5.jpg";
import principal_464 from "../assets/images/productos-img/464/principal.jpg";
import carousel2_464 from "../assets/images/productos-img/464/carousel2.jpg";
import carousel3_464 from "../assets/images/productos-img/464/carousel3.jpg";
import carousel4_464 from "../assets/images/productos-img/464/carousel4.jpg";
import carousel5_464 from "../assets/images/productos-img/464/carousel5.jpg";
import principal_465 from "../assets/images/productos-img/465/principal.jpg";
import carousel2_465 from "../assets/images/productos-img/465/carousel2.jpg";
import carousel3_465 from "../assets/images/productos-img/465/carousel3.jpg";
import carousel4_465 from "../assets/images/productos-img/465/carousel4.jpg";
import carousel5_465 from "../assets/images/productos-img/465/carousel5.jpg";
import principal_466 from "../assets/images/productos-img/466/principal.jpg";
import carousel2_466 from "../assets/images/productos-img/466/carousel2.jpg";
import carousel3_466 from "../assets/images/productos-img/466/carousel3.jpg";
import carousel4_466 from "../assets/images/productos-img/466/carousel4.jpg";
import principal_467 from "../assets/images/productos-img/467/principal.jpg";
import carousel2_467 from "../assets/images/productos-img/467/carousel2.jpg";
import carousel3_467 from "../assets/images/productos-img/467/carousel3.jpg";
import carousel4_467 from "../assets/images/productos-img/467/carousel4.jpg";
import carousel5_467 from "../assets/images/productos-img/467/carousel5.jpg";
import principal_469 from "../assets/images/productos-img/469/principal.jpg";
import carousel2_469 from "../assets/images/productos-img/469/carousel2.jpg";
import carousel3_469 from "../assets/images/productos-img/469/carousel3.jpg";
import carousel4_469 from "../assets/images/productos-img/469/carousel4.jpg";
import carousel5_469 from "../assets/images/productos-img/469/carousel5.jpg";
import principal_501 from "../assets/images/productos-img/501/principal.jpg";
import carousel2_501 from "../assets/images/productos-img/501/carousel2.jpg";
import carousel3_501 from "../assets/images/productos-img/501/carousel3.jpg";
import carousel4_501 from "../assets/images/productos-img/501/carousel4.jpg";
import carousel5_501 from "../assets/images/productos-img/501/carousel5.jpg";
import principal_504 from "../assets/images/productos-img/504/principal.jpg";
import carousel2_504 from "../assets/images/productos-img/504/carousel2.jpg";
import carousel3_504 from "../assets/images/productos-img/504/carousel3.jpg";
import carousel4_504 from "../assets/images/productos-img/504/carousel4.jpg";
import carousel5_504 from "../assets/images/productos-img/504/carousel5.jpg";
import principal_507 from "../assets/images/productos-img/507/principal.jpg";
import carousel2_507 from "../assets/images/productos-img/507/carousel2.jpg";
import carousel3_507 from "../assets/images/productos-img/507/carousel3.jpg";
import carousel4_507 from "../assets/images/productos-img/507/carousel4.jpg";
import carousel5_507 from "../assets/images/productos-img/507/carousel5.jpg";
import principal_508 from "../assets/images/productos-img/508/principal.jpg";
import carousel2_508 from "../assets/images/productos-img/508/carousel2.jpg";
import carousel3_508 from "../assets/images/productos-img/508/carousel3.jpg";
import carousel4_508 from "../assets/images/productos-img/508/carousel4.jpg";
import carousel5_508 from "../assets/images/productos-img/508/carousel5.jpg";
import principal_509 from "../assets/images/productos-img/509/principal.jpg";
import carousel2_509 from "../assets/images/productos-img/509/carousel2.jpg";
import carousel3_509 from "../assets/images/productos-img/509/carousel3.jpg";
import carousel4_509 from "../assets/images/productos-img/509/carousel4.jpg";
import carousel5_509 from "../assets/images/productos-img/509/carousel5.jpg";
import principal_510 from "../assets/images/productos-img/510/principal.jpg";
import carousel2_510 from "../assets/images/productos-img/510/carousel2.jpg";
import carousel3_510 from "../assets/images/productos-img/510/carousel3.jpg";
import carousel4_510 from "../assets/images/productos-img/510/carousel4.jpg";
import carousel5_510 from "../assets/images/productos-img/510/carousel5.jpg";
import principal_511 from "../assets/images/productos-img/511/principal.jpg";
import carousel2_511 from "../assets/images/productos-img/511/carousel2.jpg";
import carousel3_511 from "../assets/images/productos-img/511/carousel3.jpg";
import carousel4_511 from "../assets/images/productos-img/511/carousel4.jpg";
import carousel5_511 from "../assets/images/productos-img/511/carousel5.jpg";
import principal_513 from "../assets/images/productos-img/513/principal.jpg";
import carousel2_513 from "../assets/images/productos-img/513/carousel2.jpg";
import carousel3_513 from "../assets/images/productos-img/513/carousel3.jpg";
import carousel4_513 from "../assets/images/productos-img/513/carousel4.jpg";
import carousel5_513 from "../assets/images/productos-img/513/carousel5.jpg";
import principal_514 from "../assets/images/productos-img/514/principal.jpg";
import carousel2_514 from "../assets/images/productos-img/514/carousel2.jpg";
import carousel3_514 from "../assets/images/productos-img/514/carousel3.jpg";
import carousel4_514 from "../assets/images/productos-img/514/carousel4.jpg";
import carousel5_514 from "../assets/images/productos-img/514/carousel5.jpg";
import principal_517 from "../assets/images/productos-img/517/principal.jpg";
import carousel2_517 from "../assets/images/productos-img/517/carousel2.jpg";
import carousel3_517 from "../assets/images/productos-img/517/carousel3.jpg";
import carousel4_517 from "../assets/images/productos-img/517/carousel4.jpg";
import carousel5_517 from "../assets/images/productos-img/517/carousel5.jpg";
import principal_520 from "../assets/images/productos-img/520/principal.jpg";
import carousel2_520 from "../assets/images/productos-img/520/carousel2.jpg";
import carousel3_520 from "../assets/images/productos-img/520/carousel3.jpg";
import carousel4_520 from "../assets/images/productos-img/520/carousel4.jpg";
import carousel5_520 from "../assets/images/productos-img/520/carousel5.jpg";
import principal_521 from "../assets/images/productos-img/521/principal.jpg";
import carousel2_521 from "../assets/images/productos-img/521/carousel2.jpg";
import carousel3_521 from "../assets/images/productos-img/521/carousel3.jpg";
import carousel4_521 from "../assets/images/productos-img/521/carousel4.jpg";
import carousel5_521 from "../assets/images/productos-img/521/carousel5.jpg";
import principal_522 from "../assets/images/productos-img/522/principal.jpg";
import carousel2_522 from "../assets/images/productos-img/522/carousel2.jpg";
import carousel3_522 from "../assets/images/productos-img/522/carousel3.jpg";
import carousel4_522 from "../assets/images/productos-img/522/carousel4.jpg";
import principal_523 from "../assets/images/productos-img/523/principal.jpg";
import carousel2_523 from "../assets/images/productos-img/523/carousel2.jpg";
import carousel3_523 from "../assets/images/productos-img/523/carousel3.jpg";
import carousel4_523 from "../assets/images/productos-img/523/carousel4.jpg";
import carousel5_523 from "../assets/images/productos-img/523/carousel5.jpg";
import principal_524 from "../assets/images/productos-img/524/principal.jpg";
import carousel2_524 from "../assets/images/productos-img/524/carousel2.jpg";
import carousel3_524 from "../assets/images/productos-img/524/carousel3.jpg";
import carousel4_524 from "../assets/images/productos-img/524/carousel4.jpg";
import carousel5_524 from "../assets/images/productos-img/524/carousel5.jpg";
import principal_600 from "../assets/images/productos-img/600/principal.jpg";
import carousel2_600 from "../assets/images/productos-img/600/carousel2.jpg";
import carousel3_600 from "../assets/images/productos-img/600/carousel3.jpg";
import carousel4_600 from "../assets/images/productos-img/600/carousel4.jpg";
import carousel5_600 from "../assets/images/productos-img/600/carousel5.jpg";
import principal_601 from "../assets/images/productos-img/601/principal.jpg";
import carousel2_601 from "../assets/images/productos-img/601/carousel2.jpg";
import carousel3_601 from "../assets/images/productos-img/601/carousel3.jpg";
import carousel4_601 from "../assets/images/productos-img/601/carousel4.jpg";
import carousel5_601 from "../assets/images/productos-img/601/carousel5.jpg";
import principal_602 from "../assets/images/productos-img/602/principal.jpg";
import carousel2_602 from "../assets/images/productos-img/602/carousel2.jpg";
import carousel3_602 from "../assets/images/productos-img/602/carousel3.jpg";
import carousel4_602 from "../assets/images/productos-img/602/carousel4.jpg";
import carousel5_602 from "../assets/images/productos-img/602/carousel5.jpg";
import principal_603 from "../assets/images/productos-img/603/principal.jpg";
import carousel2_603 from "../assets/images/productos-img/603/carousel2.jpg";
import carousel3_603 from "../assets/images/productos-img/603/carousel3.jpg";
import carousel4_603 from "../assets/images/productos-img/603/carousel4.jpg";
import carousel5_603 from "../assets/images/productos-img/603/carousel5.jpg";
import principal_604 from "../assets/images/productos-img/604/principal.jpg";
import carousel2_604 from "../assets/images/productos-img/604/carousel2.jpg";
import carousel3_604 from "../assets/images/productos-img/604/carousel3.jpg";
import carousel4_604 from "../assets/images/productos-img/604/carousel4.jpg";
import carousel5_604 from "../assets/images/productos-img/604/carousel5.jpg";
import principal_700 from "../assets/images/productos-img/700/principal.jpg";
import carousel2_700 from "../assets/images/productos-img/700/carousel2.jpg";
import carousel3_700 from "../assets/images/productos-img/700/carousel3.jpg";
import carousel4_700 from "../assets/images/productos-img/700/carousel4.jpg";
import carousel5_700 from "../assets/images/productos-img/700/carousel5.jpg";
import principal_701 from "../assets/images/productos-img/701/principal.jpg";
import carousel2_701 from "../assets/images/productos-img/701/carousel2.jpg";
import carousel3_701 from "../assets/images/productos-img/701/carousel3.jpg";
import carousel4_701 from "../assets/images/productos-img/701/carousel4.jpg";
import carousel5_701 from "../assets/images/productos-img/701/carousel5.jpg";
import principal_702 from "../assets/images/productos-img/702/principal.jpg";
import carousel2_702 from "../assets/images/productos-img/702/carousel2.jpg";
import carousel3_702 from "../assets/images/productos-img/702/carousel3.jpg";
import carousel4_702 from "../assets/images/productos-img/702/carousel4.jpg";
import carousel5_702 from "../assets/images/productos-img/702/carousel5.jpg";
import principal_703 from "../assets/images/productos-img/703/principal.jpg";
import carousel2_703 from "../assets/images/productos-img/703/carousel2.jpg";
import carousel3_703 from "../assets/images/productos-img/703/carousel3.jpg";
import carousel4_703 from "../assets/images/productos-img/703/carousel4.jpg";
import carousel5_703 from "../assets/images/productos-img/703/carousel5.jpg";
import principal_704 from "../assets/images/productos-img/704/principal.jpg";
import carousel2_704 from "../assets/images/productos-img/704/carousel2.jpg";
import carousel3_704 from "../assets/images/productos-img/704/carousel3.jpg";
import carousel4_704 from "../assets/images/productos-img/704/carousel4.jpg";
import carousel5_704 from "../assets/images/productos-img/704/carousel5.jpg";
import principal_705 from "../assets/images/productos-img/705/principal.jpg";
import carousel2_705 from "../assets/images/productos-img/705/carousel2.jpg";
import carousel3_705 from "../assets/images/productos-img/705/carousel3.jpg";
import carousel4_705 from "../assets/images/productos-img/705/carousel4.jpg";
import carousel5_705 from "../assets/images/productos-img/705/carousel5.jpg";
import principal_706 from "../assets/images/productos-img/706/principal.jpg";
import carousel2_706 from "../assets/images/productos-img/706/carousel2.jpg";
import carousel3_706 from "../assets/images/productos-img/706/carousel3.jpg";
import carousel4_706 from "../assets/images/productos-img/706/carousel4.jpg";
import carousel5_706 from "../assets/images/productos-img/706/carousel5.jpg";
import principal_707 from "../assets/images/productos-img/707/principal.jpg";
import carousel2_707 from "../assets/images/productos-img/707/carousel2.jpg";
import carousel3_707 from "../assets/images/productos-img/707/carousel3.jpg";
import carousel4_707 from "../assets/images/productos-img/707/carousel4.jpg";
import carousel5_707 from "../assets/images/productos-img/707/carousel5.jpg";
import principal_708 from "../assets/images/productos-img/708/principal.jpg";
import carousel2_708 from "../assets/images/productos-img/708/carousel2.jpg";
import carousel3_708 from "../assets/images/productos-img/708/carousel3.jpg";
import carousel4_708 from "../assets/images/productos-img/708/carousel4.jpg";
import carousel5_708 from "../assets/images/productos-img/708/carousel5.jpg";
import principal_709 from "../assets/images/productos-img/709/principal.jpg";
import carousel2_709 from "../assets/images/productos-img/709/carousel2.jpg";
import carousel3_709 from "../assets/images/productos-img/709/carousel3.jpg";
import carousel4_709 from "../assets/images/productos-img/709/carousel4.jpg";
import carousel5_709 from "../assets/images/productos-img/709/carousel5.jpg";
import principal_710 from "../assets/images/productos-img/710/principal.jpg";
import carousel2_710 from "../assets/images/productos-img/710/carousel2.jpg";
import carousel3_710 from "../assets/images/productos-img/710/carousel3.jpg";
import carousel4_710 from "../assets/images/productos-img/710/carousel4.jpg";
import carousel5_710 from "../assets/images/productos-img/710/carousel5.jpg";
import principal_711 from "../assets/images/productos-img/711/principal.jpg";
import carousel2_711 from "../assets/images/productos-img/711/carousel2.jpg";
import carousel3_711 from "../assets/images/productos-img/711/carousel3.jpg";
import carousel4_711 from "../assets/images/productos-img/711/carousel4.jpg";
import carousel5_711 from "../assets/images/productos-img/711/carousel5.jpg";
import principal_712 from "../assets/images/productos-img/712/principal.jpg";
import carousel2_712 from "../assets/images/productos-img/712/carousel2.jpg";
import carousel3_712 from "../assets/images/productos-img/712/carousel3.jpg";
import carousel4_712 from "../assets/images/productos-img/712/carousel4.jpg";
import carousel5_712 from "../assets/images/productos-img/712/carousel5.jpg";
import principal_713 from "../assets/images/productos-img/713/principal.jpg";
import carousel2_713 from "../assets/images/productos-img/713/carousel2.jpg";
import carousel3_713 from "../assets/images/productos-img/713/carousel3.jpg";
import carousel4_713 from "../assets/images/productos-img/713/carousel4.jpg";
import carousel5_713 from "../assets/images/productos-img/713/carousel5.jpg";
import principal_714 from "../assets/images/productos-img/714/principal.jpg";
import carousel2_714 from "../assets/images/productos-img/714/carousel2.jpg";
import carousel3_714 from "../assets/images/productos-img/714/carousel3.jpg";
import carousel4_714 from "../assets/images/productos-img/714/carousel4.jpg";
import carousel5_714 from "../assets/images/productos-img/714/carousel5.jpg";
import principal_715 from "../assets/images/productos-img/715/principal.jpg";
import carousel2_715 from "../assets/images/productos-img/715/carousel2.jpg";
import carousel3_715 from "../assets/images/productos-img/715/carousel3.jpg";
import carousel4_715 from "../assets/images/productos-img/715/carousel4.jpg";
import carousel5_715 from "../assets/images/productos-img/715/carousel5.jpg";
import principal_800 from "../assets/images/productos-img/800/principal.jpg";
import carousel2_800 from "../assets/images/productos-img/800/carousel2.jpg";
import carousel3_800 from "../assets/images/productos-img/800/carousel3.jpg";
import carousel4_800 from "../assets/images/productos-img/800/carousel4.jpg";
import carousel5_800 from "../assets/images/productos-img/800/carousel5.jpg";
import principal_801 from "../assets/images/productos-img/801/principal.jpg";
import carousel2_801 from "../assets/images/productos-img/801/carousel2.jpg";

const products = [
  {
    id: 0,
    item: 1,
    name: "CHIQUI NENES",
    image: principal_0,
    size: "2x2x2",
    age: "2-5 años",
    descripcion:
      "Inflable con diseño de personajes de Disney, con punchi. Ideal para espacios reducidos.",
    filtro: ["inflables", "chico", "2-5 años"],
    carouselImages: [principal_0, carousel2_0],
  },
  {
    id: 1,
    item: 2,
    name: "CHIQUI NENAS",
    image: principal_1,
    size: "2x2x2",
    age: "2-5 años",
    descripcion:
      "Inflable con diseño de princesas, con punchi. Ideal para espacios reducidos.",
    filtro: ["inflables", "chico", "2-5 años"],
    carouselImages: [
      principal_1,
      carousel2_1,
      carousel3_1,
      carousel4_1,
      carousel5_1,
    ],
  },
  {
    id: 2,
    item: 3,
    name: "BABY",
    image: principal_2,
    size: "2x2,50x2",
    age: "1-4 años",
    descripcion: "Dos punchis internos. Rampa de acceso.",
    filtro: ["inflables", "chico", "2-5 años"],
    carouselImages: [
      principal_2,
      carousel2_2,
      carousel3_2,
      carousel4_2,
      carousel5_2,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/84SSzemqDws"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 3,
    item: 4,
    name: "CASITA PELOTERO",
    image: principal_3,
    size: "2x2x2,30",
    age: "2-5 años",
    descripcion: "Con pelotitas y colchón inflable.",
    filtro: ["inflables", "chico", "2-5 años", "con pelotero"],
    carouselImages: [
      principal_3,
      carousel2_3,
      carousel3_3,
      carousel4_3,
      carousel5_3,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/0YrKm2h0dD4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 4,
    item: 5,
    name: "PELOTERO CANDY",
    image: principal_4,
    size: "2x2x2,50",
    age: "2-5 años",
    descripcion: "Con pelotitas.",
    filtro: ["inflables", "chico", "2-5 años", "con pelotero"],
    carouselImages: [
      principal_4,
      carousel2_4,
      carousel3_4,
      carousel4_4,
      carousel5_4,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/5TPqUtobwL4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 5,
    item: 6,
    name: "INFANTIL ",
    image: principal_5,
    size: "2,50x4x2",
    age: "2-5 años",
    plotter: true,
    descripcion: "Con tobogán y punchi.",
    filtro: ["inflables", "chico", "2-5 años", "plotter"],
    carouselImages: [
      principal_5,
      carousel2_5,
      carousel3_5,
      carousel4_5,
      carousel5_5,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/uV64dfBOFBY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 6,
    item: 7,
    name: "MAGICO",
    image: principal_6,
    size: "3x5x2,30",
    age: "2-8 años",
    plotter: true,
    descripcion: "Con tobogán y punchi.",
    filtro: ["inflables", "mediano", "2-5 años", "5-9 años", "plotter"],
    carouselImages: [principal_6, carousel2_6, carousel3_6, carousel4_6],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/GkGtj2H7cMs"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 7,
    item: 8,
    name: "ELEFANTE",
    image: principal_7,
    size: "3,50x5x2,20",
    age: "2-9 años",
    descripcion: "Con tobogán, elefante grande interno para subirse y sapito.",
    filtro: ["inflables", "mediano", "2-5 años", "5-9 años", "tematico"],
    carouselImages: [
      principal_7,
      carousel2_7,
      carousel3_7,
      carousel4_7,
      carousel5_7,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/s3ZbqSJQWAs"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 8,
    item: 9,
    name: "ALEGRIA",
    image: principal_8,
    size: "4x5",
    age: "2-9 años",
    plotter: true,
    descripcion: "Con tobogán, punchis y caritas.",
    filtro: ["inflables", "mediano", "2-5 años", "5-9 años", "plotter"],
    carouselImages: [
      principal_8,
      carousel2_8,
      carousel3_8,
      carousel4_8,
      carousel5_8,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/G9rob2QeDqI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 9,
    item: 10,
    name: "FANTASIA",
    image: principal_9,
    size: "4x5x2,50",
    age: "2-9 años",
    plotter: true,
    descripcion: "Con tobogán y punchi.",
    filtro: ["inflables", "mediano", "2-5 años", "5-9 años", "plotter"],
    carouselImages: [
      principal_9,
      carousel2_9,
      carousel3_9,
      carousel4_9,
      carousel5_9,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/wCBWE18hyAI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 10,
    item: 11,
    name: "FUTBOL",
    image: principal_10,
    size: "3x5x3",
    age: "3-9 años",
    plotter: true,
    descripcion: "Con tobogán y punchis, ambientado con temática de fútbol.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "tematico",
      "plotter",
    ],
    carouselImages: [
      principal_10,
      carousel2_10,
      carousel3_10,
      carousel4_10,
      carousel5_10,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/2M9OOosOQxU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 11,
    item: 12,
    name: "UNICORNIOS Y PONYS",
    image: principal_11,
    size: "4x5x3",
    age: "3-9 años",
    descripcion:
      "Con temática exclusiva de ponys y unicornios, con tobogán, 2 ponys para cabalgar y rampa de acceso.",
    filtro: ["inflables", "mediano", "2-5 años", "5-9 años", "tematico"],
    carouselImages: [
      principal_11,
      carousel2_11,
      carousel3_11,
      carousel4_11,
      carousel5_11,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/x-j6iiEHMtk"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 12,
    item: 13,
    name: "BOSQUE ENCANTADO",
    image: principal_12,
    size: "4x5x3",
    age: "2-8 años",
    descripcion:
      "Inflable con temática de bosque que cuenta con caracol, duendecito, hongos y tobogán.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "tematico",
      "exclusivo",
    ],
    carouselImages: [
      principal_12,
      carousel2_12,
      carousel3_12,
      carousel4_12,
      carousel5_12,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/rzxXs65k85w"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 13,
    item: 14,
    name: "PRINCESAS",
    image: principal_13,
    size: "4x4x3,10",
    age: "3-9 años",
    descripcion: "Con tobogán, punchi y temática de princesas.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "tematico",
      "exclusivo",
    ],
    carouselImages: [
      principal_13,
      carousel2_13,
      carousel3_13,
      carousel4_13,
      carousel5_13,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/R3D46nXvW1Y"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 14,
    item: 15,
    name: "MICKEY Y MINNIE",
    image: principal_14,
    size: "4x5x3,60",
    age: "3-9 años",
    descripcion: "Con tobogán e imágenes gigantes de Mickey y Minnie.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "tematico",
      "exclusivo",
    ],
    carouselImages: [
      principal_14,
      carousel2_14,
      carousel3_14,
      carousel4_14,
      carousel5_14,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/-pGA0XeH6M8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 15,
    item: 16,
    name: "CARS",
    image: principal_15,
    size: "3x5x2,30",
    age: "3-8 años",
    descripcion:
      "El auto más famoso para saltar y divertirse. Con punchi y tobogán.",
    filtro: ["inflables", "mediano", "2-5 años", "5-9 años", "tematico"],
    carouselImages: [
      principal_15,
      carousel2_15,
      carousel3_15,
      carousel4_15,
      carousel5_15,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/THzPoP9ROOk"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 16,
    item: 17,
    name: "SUPER HEROES",
    image: principal_16,
    size: "4x5x3,60",
    age: "5-9 años",
    descripcion:
      "Punchi y rueda, túnel debajo del tobogán. Con temática de super héroes.",
    filtro: ["inflables", "mediano", "5-9 años", "tematico"],
    carouselImages: [
      principal_16,
      carousel2_16,
      carousel3_16,
      carousel4_16,
      carousel5_16,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/jPrYgxMN9_8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 17,
    item: 18,
    name: "HOMBRE ARAÑA",
    image: principal_17,
    size: "4x5x3,50",
    age: "3-10 años",
    descripcion: "Con tobogán curvo, punchis y tuerca.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "9-13 años",
      "tematico",
    ],
    carouselImages: [
      principal_17,
      carousel2_17,
      carousel3_17,
      carousel4_17,
      carousel5_17,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/UlQAVmepKzI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 18,
    item: 19,
    name: "ARCOIRIS",
    image: principal_18,
    size: "3x6x3",
    age: "3-9 años",
    plotter: true,
    descripcion: "Con tobogán y punchis.",
    filtro: ["inflables", "mediano", "2-5 años", "5-9 años", "plotter"],
    carouselImages: [
      principal_18,
      carousel2_18,
      carousel3_18,
      carousel4_18,
      carousel5_18,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/2N-w1QEiuIM"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 19,
    item: 20,
    name: "CAMION",
    image: principal_19,
    size: "3x6x2,50",
    age: "3-11 años",
    descripcion: "Con trepadora, tuerca interna, tobogán y rampa de acceso.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "9-13 años",
      "tematico",
    ],
    carouselImages: [
      principal_19,
      carousel2_19,
      carousel3_19,
      carousel4_19,
      carousel5_19,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/75pwgos6Ul8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 20,
    item: 21,
    name: "TREN THOMAS ",
    image: principal_20,
    size: "3x6x2,50",
    age: "4-9 años",
    descripcion:
      "Novedoso inflable con recorrido interno, punchi, rueda y tobogán interno. Con decorado de temática de Tren Thomas.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "tematico",
      "exclusivo",
    ],
    carouselImages: [
      principal_20,
      carousel2_20,
      carousel3_20,
      carousel4_20,
      carousel5_20,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/ezYgl7jX7lI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 21,
    item: 22,
    name: "DINO",
    image: principal_21,
    size: "4x4x3,50",
    age: "4-9 años",
    descripcion:
      "Inflable temático de dino con area para saltar, túnel,  trepadora y tobogán.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "exclusivo",
      "tematico",
    ],
    carouselImages: [
      principal_21,
      carousel2_21,
      carousel3_21,
      carousel4_21,
      carousel5_21,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/8bNm_gVAkf0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 22,
    item: 23,
    name: "CANDY",
    image: principal_22,
    size: "4x4x3,50",
    age: "4-9 años",
    descripcion:
      "Amplia area para saltar, con trepadora y tobogán. Con temática exclusiva de dulces.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "exclusivo",
      "tematico",
    ],
    carouselImages: [
      principal_22,
      carousel2_22,
      carousel3_22,
      carousel4_22,
      carousel5_22,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/Flai339oiII"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 23,
    item: [22, 4],
    name: "DINO CON PELOTERO",
    image: principal_23,
    size: "6x4x3,50",
    age: "2-9 años",
    descripcion:
      "Inflable temático de dino con area para saltar, túnel, trepadora y tobogán. Con pelotero de pelotitas.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "exclusivo",
      "tematico",
    ],
    carouselImages: [
      principal_23,
      carousel2_23,
      carousel3_23,
      carousel4_23,
      carousel5_23,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/thHKDpD6ayM"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 24,
    item: [23, 5],
    name: "CANDY CON PELOTERO",
    image: principal_24,
    size: "6x4x3,50",
    age: "2-9 años",
    descripcion:
      "Amplia area para saltar, con trepadora y tobogán. Con temática exclusiva de dulces. Incluye pelotero.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "exclusivo",
      "tematico",
    ],
    carouselImages: [
      principal_24,
      carousel2_24,
      carousel3_24,
      carousel4_24,
      carousel5_24,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/AXnb6lg5i3I"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 25,
    item: 24,
    name: "JUNGLA CON PELOTERO",
    image: principal_25,
    size: "4x6x3,30",
    age: "2-9 años",
    plotter: true,
    descripcion: "Con pelotero, área para saltar, tobogán y rampa de acceso.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "plotter",
      "tematico",
    ],
    carouselImages: [
      principal_25,
      carousel2_25,
      carousel3_25,
      carousel4_25,
      carousel5_25,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/l2oJEXVeZXY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 26,
    item: 25,
    name: "CASTILLO FIESTA CON PELOTERO",
    image: principal_26,
    size: "4x6x3,20",
    age: "2-9 años",
    plotter: true,
    descripcion: "Con pelotero, área para saltar y tobogán.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "plotter",
    ],
    carouselImages: [
      principal_26,
      carousel2_26,
      carousel3_26,
      carousel4_26,
      carousel5_26,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/ZMIXlUd-Shw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 27,
    item: 26,
    name: "TREPADORA",
    image: principal_27,
    size: "3x7,50x2,70",
    age: "5-13 años",
    descripcion: "Con amplia área para saltar, túnel, repadora y tobogán.",
    filtro: ["inflables", "mediano", "5-9 años", "9-13 años"],
    carouselImages: [
      principal_27,
      carousel2_27,
      carousel3_27,
      carousel4_27,
      carousel5_27,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/FJe5PmVKhD4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  // {
  //   id: 28,
  //   item: 27,
  //   name: "CITY KIDS",
  //   image: principal_28,
  //   size: "4x4x2,30",
  //   age: "5-9 años",
  //   descripcion: "Con túnel, trepadora, tobogán y punchis.",
  //   filtro: ["inflables","mediano","5-9 años","tematico","exclusivo"],
  //   carouselImages: [
  //
  //     principal_28,
  //     carousel2_28,
  //     carousel3_28,
  //     carousel4_28,
  //     carousel5_28,
  //   ],
  // },
  {
    id: 29,
    item: 28,
    name: "PIRATAS",
    image: principal_29,
    size: "5x5x3,80",
    age: "5-13 años",
    descripcion: "Con obstáculos, túnel trepadora y tobogán interno.",
    filtro: [
      "inflables",
      "mediano",
      "5-9 años",
      "9-13 años",
      "tematico",
      "exclusivo",
    ],
    carouselImages: [
      principal_29,
      carousel2_29,
      carousel3_29,
      carousel4_29,
      carousel5_29,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/EL2A7kbXKwc"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 30,
    item: 29,
    name: "CANCHA DE FUTBOL 4X6",
    image: principal_30,
    size: "6x4x4",
    age: "4-9 años",
    descripcion:
      "Cancha de fútbol exclusiva con colchón inflable. Aros de basquet internos y solicitar red de voley. No incluye pelota.",
    filtro: ["inflables", "mediano", "2-5 años", "5-9 años", "deporte"],
    carouselImages: [
      principal_30,
      carousel2_30,
      carousel3_30,
      carousel4_30,
      carousel5_30,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/YBp5UhD1mBw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 31,
    item: 30,
    name: "CANCHA DE FUTBOL 4X9",
    image: principal_31,
    size: "4x9",
    age: "5-13 años",
    plotter: true,
    descripcion:
      "Cancha de Fútbol y básquet, sin colchón inflable. Solicitar red de voley y fútbol tenis. No incluye pelota. ",
    filtro: ["inflables", "mediano", "5-9 años", "9-13 años", "deporte"],
    carouselImages: [
      principal_31,
      carousel2_31,
      carousel3_31,
      carousel4_31,
      carousel5_31,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/pAvekx8YLWE"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 32,
    item: 30,
    name: "CANCHA METEGOL HUMANO ",
    image: principal_32,
    size: "4x9",
    age: "5-13 años",
    descripcion:
      "Cancha de Fútbol con metegol humano, sin colchón inflable. Solicitar red de voley y fútbol tenis, incluye aros de básquet. No incluye pelota. ",
    filtro: ["inflables", "mediano", "5-9 años", "9-13 años", "deporte"],
    carouselImages: [
      principal_32,
      carousel2_32,
      carousel3_32,
      carousel4_32,
      carousel5_32,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/svD8RciOM94"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 33,
    item: 31,
    name: "ARCO ATAJA PENALES",
    image: principal_33,
    size: "4x5x3",
    age: "> 5 años ",
    descripcion:
      "Arco inflable, súper original y divertido, ideal para generar competencia y lo puede usar toda la familia. No incluye pelota. Puede adicionar la lona panel con un valor extra de $5.000. APTO ADULTOS",
    filtro: ["inflables", "mediano", "5-9 años", "9-13 años", "deporte"],
    carouselImages: [
      principal_33,
      carousel2_33,
      carousel3_33,
      carousel4_33,
      carousel5_33,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/1B9RS1gpSJw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 34,
    item: 31,
    name: "ARCO CON PANEL",
    image: principal_34,
    size: "4x5x3",
    age: "5-13 años",
    descripcion:
      "Con lona para patear y embocar en los agujeros. No incluye pelota. ",
    filtro: ["inflables", "mediano", "5-9 años", "9-13 años", "deporte"],
    carouselImages: [
      principal_34,
      carousel2_34,
      carousel3_34,
      carousel4_34,
      carousel5_34,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/0UR-DitRNMQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 35,
    item: 32,
    name: "MURO 1",
    image: principal_35,
    size: "3x6x4,50",
    age: "5-13 años",
    descripcion: "Con trepadora y tobogán alto.",
    filtro: ["inflables", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      principal_35,
      carousel2_35,
      carousel3_35,
      carousel4_35,
      carousel5_35,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/1BtgDbCPOXg"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 36,
    item: 33,
    name: "MURO 2",
    image: principal_36,
    size: "4x6x4,50",
    age: "5-13 años",
    descripcion: "Con trepadora y tobogán alto.",
    filtro: ["inflables", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      principal_36,
      carousel2_36,
      carousel3_36,
      carousel4_36,
      carousel5_36,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/AWJsTXcJHrc"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 37,
    item: 34,
    name: "SUPER ESCALADOR",
    image: principal_37,
    size: "6x5x5",
    age: "5-13 años",
    descripcion:
      "El inflable más alto, super imponente y divertido. Con doble ingreso, doble trepadora y tobogán alto y ancho.",
    filtro: ["inflables", "grande", "5-9 años", "9-13 años", "exclusivo"],
    carouselImages: [
      principal_37,
      carousel2_37,
      carousel3_37,
      carousel4_37,
      carousel5_37,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/aNzyfymTQ-M"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 38,
    item: 35,
    name: "ESCALADOR MULTICOLOR",
    image: principal_38,
    size: "3x5x3,50",
    age: "5-11 años",
    descripcion: "Con trepadora y tobogán alto.",
    filtro: ["inflables", "grande", "5-9 años", "9-13 años"],
    carouselImages: [
      principal_38,
      carousel2_38,
      carousel3_38,
      carousel4_38,
      carousel5_38,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/NxLnVudkl_w"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 39,
    item: 36,
    name: "ESCALADOR SURF",
    image: principal_39,
    size: "6,20x4x4,50",
    age: "5-13 años",
    descripcion:
      "Con trepadora, tobogán alto y decorado con tablas de surf y nenes surfeando en los laterales.",
    filtro: ["inflables", "grande", "5-9 años", "9-13 años", "tematico"],
    carouselImages: [
      principal_39,
      carousel2_39,
      carousel3_39,
      carousel4_39,
      carousel5_39,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/4MtYxWJX5DU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 40,
    item: 37,
    name: "MUNDO MARINO",
    image: principal_40,
    size: "5x5,50x4",
    age: "5-13 años",
    descripcion:
      "Con muro y tobogán, con temática marina, incluye Nemo, hipocampo y dibujos varios.",
    filtro: [
      "inflables",
      "grande",
      "5-9 años",
      "9-13 años",
      "tematico",
      "exclusivo",
    ],
    carouselImages: [
      principal_40,
      carousel2_40,
      carousel3_40,
      carousel4_40,
      carousel5_40,
    ],
  },
  {
    id: 41,
    item: 38,
    name: "AVENTURA 1",
    image: principal_41,
    size: "6x6x4",
    age: "5-11 años",
    descripcion: "Con doble tobogán: curvo y recto.",
    filtro: ["inflables", "grande", "5-9 años", "9-13 años", "exclusivo"],
    carouselImages: [
      principal_41,
      carousel2_41,
      carousel3_41,
      carousel4_41,
      carousel5_41,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/wiL3w10ZR_k"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 42,
    item: 39,
    name: "AVENTURA 2",
    image: principal_42,
    size: "6x5x4,50",
    age: "5-13 años",
    descripcion: "Con doble tobogán: curvo y recto.",
    filtro: ["inflables", "grande", "5-9 años", "9-13 años", "exclusivo"],
    carouselImages: [
      principal_42,
      carousel2_42,
      carousel3_42,
      carousel4_42,
      carousel5_42,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/fXJ7cgm5z0A"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 43,
    item: 40,
    name: "SALTARIN",
    image: principal_43,
    size: "2,5x3,5x2",
    age: "2-5 años",
    plotter: true,
    descripcion: "Área de salto, dos punchi. Sin tobogán.",
    filtro: ["inflables", "chico", "2-5 años", "plotter"],
    carouselImages: [
      principal_43,
      carousel2_43,
      carousel3_43,
      carousel4_43,
      carousel5_43,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/8N9c88myKSo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 44,
    item: 41,
    name: "PELOTERO BLANCO",
    image: principal_44,
    size: "2,5x2x1,70",
    age: "2-6 años",
    descripcion: "Con pelotitas color pastel.",
    filtro: [
      "inflables",
      "con pelotero",
      "chico",
      "2-5 años",
      "exclusivo",
      "blanco",
    ],
    carouselImages: [
      principal_44,
      carousel2_44,
      carousel3_44,
      carousel4_44,
      carousel5_44,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/2UZnA0XmHvI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 45,
    item: 42,
    name: "BLANCO",
    image: principal_45,
    size: "3,70x3x3",
    age: "2-8 años",
    descripcion:
      "Inflable novedoso, que cuenta con área de salto, un tobogán y se le puede agregar globos y puede agregar vinilo autoadhesivo. Ideal para bautismos, comuniones y casamientos",
    filtro: [
      "inflables",
      "2-5 años",
      "5-9 años",
      "mediano",
      "exclusivo",
      "tematico",
      "blanco",
    ],
    carouselImages: [
      principal_45,
      carousel2_45,
      carousel3_45,
      carousel4_45,
      carousel5_45,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/GFLnhQSDoJs"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 46,
    item: 43,
    name: "SAFARI ",
    image: principal_46,
    size: "4x4x5",
    age: "3-9 años",
    descripcion:
      "Área para saltar. Trepadora y tobogán. Ambientado con temática de safari",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "tematico",
      "exclusivo",
    ],
    carouselImages: [
      principal_46,
      carousel2_46,
      carousel3_46,
      carousel4_46,
      carousel5_46,
    ],
  },
  {
    id: 47,
    item: [42, 41],
    name: "BLANCO CON PELOTERO",
    image: principal_47,
    size: "3,70x5x3",
    age: "2-8 años",
    descripcion:
      "Inflable novedoso, que cuenta con área de salto, un tobogán y se le puede agregar globos y puede agregar vinilo autoadhesivo. Incluye pelotero con pelotitas color pastel. Ideal para bautismos, comuniones y casamientos",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "exclusivo",
      "tematico",
      "blanco",
    ],
    carouselImages: [
      principal_47,
      carousel2_47,
      carousel3_47,
      carousel4_47,
      carousel5_47,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/H5ptufxaz8E"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 48,
    item: [43, 4],
    name: "SAFARI CON CASITA PELOTERO",
    image: principal_48,
    size: "6x4x5",
    age: "2-9 años",
    descripcion:
      "Con pelotero, área para saltar, trepadora y tobogán. Ambientado con temática de safari. Incluye pelotero",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "exclusivo",
      "tematico",
    ],
    carouselImages: [
      principal_48,
      carousel2_48,
      carousel3_48,
      carousel4_48,
      carousel5_48,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/NQf1kK2AhL8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 49,
    item: [43, 204],
    name: "SAFARI CON TORTUGA PELOTERO",
    image: principal_49,
    size: "4x5,50x5",
    age: "2-9 años",
    descripcion:
      "Con pelotero, área para saltar, trepadora y tobogán. Ambientado con temática de safari. Con pelotero en forma de tortuga.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "exclusivo",
      "tematico",
    ],
    carouselImages: [
      principal_49,
      carousel2_49,
      carousel3_49,
      carousel4_49,
      carousel5_49,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/Xa0mdn9VVqU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 50,
    item: [43, 4, 204],
    name: "SAFARI CON DOBLE PELOTERO",
    image: principal_50,
    size: "6x5,50x5",
    age: "2-9 años",
    descripcion:
      "Con doble pelotero, área para saltar, trepadora y tobogán. Ambientado con temática de safari. Con doble pelotero, uno de ellos con forma de tortuga.",
    filtro: [
      "inflables",
      "mediano",
      "2-5 años",
      "5-9 años",
      "con pelotero",
      "exclusivo",
      "tematico",
    ],
    carouselImages: [
      principal_50,
      carousel2_50,
      carousel3_50,
      carousel4_50,
      carousel5_50,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/w9SI2_HeH1o"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 51,
    item: 44,
    name: "ESTRELLITA",
    image: principal_51,
    size: "3x4,50x2,50",
    age: "2-5 años",
    descripcion:
      "Con área para saltar, punchis, temática de estrellas y tobogán.",
    filtro: ["inflables", "mediano", "2-5 años", "tematico"],
    carouselImages: [principal_51, carousel2_51],
  },
  {
    id: 100,
    item: 101,
    name: "CARRERA DE OBSTACULOS 1",
    image: principal_100,
    size: "4x7x4",
    age: "5-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_100,
      carousel2_100,
      carousel3_100,
      carousel4_100,
      carousel5_100,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/FmNomNGzfo4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 101,
    item: 102,
    name: "CARRERA DE OBSTACULOS 2",
    image: principal_101,
    size: "4x7x4",
    age: "5-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_101,
      carousel2_101,
      carousel3_101,
      carousel4_101,
      carousel5_101,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/TK_nLvjsNzg"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 102,
    item: 106,
    name: "CARRERA DE OBSTACULOS 3",
    image: principal_102,
    size: "4x8x4,50",
    age: "5-13 años",
    descripcion:
      "Múltiples y novedosos obstáculos, trepadora y el tobogán más alto. ",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_102,
      carousel2_102,
      carousel3_102,
      carousel4_102,
      carousel5_102,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/gbpEPvnxMzA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 103,
    item: [101, 102],
    name: "CARRERA DE OBSTACULOS 4",
    image: principal_103,
    size: "4x14x4",
    age: "5-13 años",
    descripcion:
      "Carrera con doble módulo, múltiples obstáculos, trepadora y tobogán. ",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_103,
      carousel2_103,
      carousel3_103,
      carousel4_103,
      carousel5_103,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/fe0yOvt8LUA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 104,
    item: [33, 103],
    name: "CARRERA DE OBSTACULOS 5",
    image: principal_104,
    size: "4x12x4,50",
    age: "5-13 años",
    descripcion:
      "Carrera con doble módulo, múltiples obstáculos, trepadora y tobogán. ",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_104,
      carousel2_104,
      carousel3_104,
      carousel4_104,
      carousel5_104,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/gUfEtBxnZCs"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 105,
    item: [26, 32],
    name: "CARRERA DE OBSTACULOS 6",
    image: principal_105,
    size: "4x14x4",
    age: "5-13 años",
    descripcion:
      "Carrera con doble módulo, trepadora, tobogán, más muro de escalada alto.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [principal_105, carousel2_105],
  },
  {
    id: 106,
    item: [26, 102],
    name: "CARRERA DE OBSTACULOS 7",
    image: principal_106,
    size: "4x14x4,50",
    age: "4-13 años",
    descripcion:
      "Carrera con doble módulo, múltiples obstáculos, túnel, trepadora y toboganes medianos.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_106,
      carousel2_106,
      carousel3_106,
      carousel4_106,
      carousel5_106,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/lsFXrFU8FdM"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 107,
    item: [36, 103],
    name: "CARRERA DESAFIO 1",
    image: principal_107,
    size: "13,5x4x4,50",
    age: "5-13 años",
    descripcion: "Escalador alto, múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_107,
      carousel2_107,
      carousel3_107,
      carousel4_107,
      carousel5_107,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/9Ta9h5jAcYU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 108,
    item: [34, 103],
    name: "CARRERA DESAFIO 2",
    image: principal_108,
    size: "13x4x5",
    age: "5-13 años",
    descripcion:
      "Escalador alto con doble acceso, múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_108,
      carousel2_108,
      carousel3_108,
      carousel4_108,
      carousel5_108,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/UjZKy9On4BQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 109,
    item: [36, 106],
    name: "CARRERA DESAFIO 3",
    image: principal_109,
    size: "12,5x4x4,50",
    age: "5-13 años",
    descripcion:
      "Carrera exclusiva y súper divertida con muchos obstáculos, doble tobogán, en dos módulos.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_109,
      carousel2_109,
      carousel3_109,
      carousel4_109,
      carousel5_109,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/rQiDBJtTeFY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 110,
    item: [34, 106],
    name: "CARRERA DESAFIO 4",
    image: principal_110,
    size: "12x4x5",
    age: "5-13 años",
    descripcion:
      "Carrera exclusiva y súper divertida con muchos obstáculos, doble tobogán, en dos módulos.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_110,
      carousel2_110,
      carousel3_110,
      carousel4_110,
      carousel5_110,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/m9XkivhhQ5w"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 111,
    item: [33, 36, 103],
    name: "CARRERA DESAFIO PRO 1",
    image: principal_111,
    size: "18,50x4",
    age: "5-13 años",
    descripcion:
      "Un verdadero desafío, con tres módulos de obstáculos y toboganes altos. IMPORTANTE Consumo eléctrico total del inflable: 18 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_111,
      carousel2_111,
      carousel3_111,
      carousel4_111,
      carousel5_111,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/vcdTQlNGDLQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 112,
    item: [33, 34, 103],
    name: "CARRERA DESAFIO PRO 2",
    image: principal_112,
    size: "18x4",
    age: "5-13 años",
    descripcion:
      "Un verdadero desafío, con tres módulos de obstáculos y toboganes altos. IMPORTANTE Consumo eléctrico total del inflable: 18 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_112,
      carousel2_112,
      carousel3_112,
      carousel4_112,
      carousel5_112,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/FzdbWAjy53M"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 113,
    item: [101, 102, 104],
    name: "SUPER CARRERA 1",
    image: principal_113,
    size: "7x10",
    age: "5-13 años",
    descripcion:
      "Carrera de obstáculos en forma de U, con 25 metros de carrera continua. Dinámica de entrada y salida con poco trayecto fuera del inflable. IMPORTANTE Consumo eléctrico total del inflable: 18 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_113,
      carousel2_113,
      carousel3_113,
      carousel4_113,
      carousel5_113,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/XCcJ4nZmTOA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 114,
    item: [105, 33, 103],
    name: "SUPER CARRERA 2",
    image: principal_114,
    size: "7x10",
    age: "5-13 años",
    descripcion:
      "Carrera de obstáculos en forma de U, con 25 metros de carrera continua. Dinámica de entrada y salida con poco trayecto fuera del inflable. IMPORTANTE Consumo eléctrico total del inflable: 18 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_114,
      carousel2_114,
      carousel3_114,
      carousel4_114,
      carousel5_114,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/gZf5fT9xLl4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 115,
    item: [32, 105, 26],
    name: "SUPER CARRERA 3",
    image: principal_115,
    size: "7x10",
    age: "5-13 años",
    descripcion:
      "Carrera de obstáculos en forma de U, con 25 metros de carrera continua. Dinámica de entrada y salida con poco trayecto fuera del inflable. IMPORTANTE Consumo eléctrico total del inflable: 18 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_115,
      carousel2_115,
      carousel3_115,
      carousel4_115,
      carousel5_115,
    ],
  },
  {
    id: 116,
    item: [104, 103, 33, 102, 101],
    name: "SUPER CARRERA PRO 1",
    image: principal_116,
    size: "7x16",
    age: "5-13 años",
    descripcion:
      "Carrera de obstáculos en forma de U, con 40 metros de carrera continua. Dinámica de entrada y salida con poco trayecto fuera del inflable. IMPORTANTE Consumo eléctrico total del inflable: 30 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_116,
      carousel2_116,
      carousel3_116,
      carousel4_116,
      carousel5_116,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/n9MvSKOhNaA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 117,
    item: [105, 103, 33, 32, 26],
    name: "SUPER CARRERA PRO 2",
    image: principal_117,
    size: "7x16",
    age: "5-13 años",
    descripcion:
      "Carrera de obstáculos en forma de U, con 40 metros de carrera continua. Dinámica de entrada y salida con poco trayecto fuera del inflable. IMPORTANTE Consumo eléctrico total del inflable: 30 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_117,
      carousel2_117,
      carousel3_117,
      carousel4_117,
      carousel5_117,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/Z-NB2YKZNyU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 118,
    item: [32, 101, 102, 104, 26],
    name: "SUPER CARRERA PRO 3",
    image: principal_118,
    size: "7x16",
    age: "5-13 años",
    descripcion:
      "Carrera de obstáculos en forma de U, con 40 metros de carrera continua. Dinámica de entrada y salida con poco trayecto fuera del inflable. IMPORTANTE Consumo eléctrico total del inflable: 30 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_118,
      carousel2_118,
      carousel3_118,
      carousel4_118,
      carousel5_118,
    ],
  },
  {
    id: 119,
    item: [104, 105, 103, 33],
    name: "CARRERA INFINITA",
    image: principal_119,
    size: "7x13",
    age: "5-13 años",
    descripcion:
      "Novedoso formato super original y divertido, sin tener que salir del inflable. Donde van a recorrer 40 metros de carrera continua. Con muchos obstáculos, trepadora, tobogán y la posibilidad de jugar postas. IMPORTANTE Consumo eléctrico total del inflable: 24 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_119,
      carousel2_119,
      carousel3_119,
      carousel4_119,
      carousel5_119,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/WjZDU45AFKQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 120,
    item: [104, 105, 103, 33, 102, 101],
    name: "MEGA CARRERA INFINITA",
    image: principal_120,
    size: "7x19",
    age: "5-13 años",
    descripcion:
      "Novedoso formato super original y divertido, sin tener que salir del inflable. Donde van a recorrer 50 metros de carrera continua. Con muchos obstáculos, trepadora, tobogán y la posibilidad de jugar postas. IMPORTANTE Consumo eléctrico total del inflable: 36 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_120,
      carousel2_120,
      carousel3_120,
      carousel4_120,
      carousel5_120,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/ZvA2loVURWE"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 121,
    item: [104, 105, 103, 33, 102, 101],
    name: "MEGA CARRERA INFINITA 2",
    image: principal_121,
    size: "13x13",
    age: "5-13 años",
    descripcion:
      "Novedoso formato super original y divertido, sin tener que salir del inflable. Donde van a recorrer 50 metros de carrera continua. Con muchos obstáculos, trepadora, tobogán y la posibilidad de jugar postas. IMPORTANTE Consumo eléctrico total del inflable: 36 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_121,
      carousel2_121,
      carousel3_121,
      carousel4_121,
      carousel5_121,
    ],
  },
  {
    id: 122,
    item: [104, 103, 33, 102, 101, 26, 32],
    name: "CARRERA SUPER MEGA PRO",
    image: principal_122,
    size: "7x25",
    age: "5-13 años",
    descripcion:
      "Súper carrera llena de adrenalina, con el recorrido más largo. Repleta de obstáculos, trepadoras y toboganes. Con 60 metros de carrera continua. IMPORTANTE Consumo eléctrico total del inflable: 42 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_122,
      carousel2_122,
      carousel3_122,
      carousel4_122,
      carousel5_122,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/1CWsEXt3-sU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 123,
    item: [32, 106],
    name: "CARRERA DE OBSTACULOS 8",
    image: principal_123,
    size: "4x14x4",
    age: "5-13 años",
    descripcion:
      "Múltiples y novedosos obstáculos, trepadora y el tobogán más alto. Con muro de escalada.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_123,
      carousel2_123,
      carousel3_123,
      carousel4_123,
      carousel5_123,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/DnC-5jZpVgw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 124,
    item: [26, 106],
    name: "CARRERA DE OBSTACULOS 9",
    image: principal_124,
    size: "4x14x4",
    age: "5-13 años",
    descripcion:
      "Múltiples y novedosos obstáculos, trepadora y el tobogán más alto. ",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_124,
      carousel2_124,
      carousel3_124,
      carousel4_124,
      carousel5_124,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/DPLK06MDNjw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 125,
    item: 107,
    name: "CARRERA DE OBSTACULOS 10",
    image: principal_125,
    size: "4x7x4",
    age: "5-13 años",
    descripcion: "Múltiples obstáculos, trepadora y tobogán.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_125,
      carousel2_125,
      carousel3_125,
      carousel4_125,
      carousel5_125,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/-yLQV-Vfm0Y"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 126,
    item: [105, 106, 107],
    name: "SUPER CARRERA 4",
    image: principal_126,
    size: "7x11",
    age: "5-13 años",
    descripcion:
      "Carrera de obstáculos en forma de U, con 25 metros de carrera continua. Dinámica de entrada y salida con poco trayecto fuera del inflable. IMPORTANTE Consumo eléctrico total del inflable: 18 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_126,
      carousel2_126,
      carousel3_126,
      carousel4_126,
      carousel5_126,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/JpQzgBQ6dBE"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 127,
    item: [106, 105, 32],
    name: "SUPER CARRERA 5",
    image: principal_127,
    size: "7x11",
    age: "5-13 años",
    descripcion:
      "Con 25 metros de carrera continua. IMPORTANTE Consumo eléctrico total del inflable: 18 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [principal_127],
  },
  {
    id: 128,
    item: [34, 107, 32, 106],
    name: "CARRERA ADRENALINA",
    image: principal_128,
    size: "5x26",
    age: "5-13 años",
    descripcion:
      "Con 24 metros de carrera con múltiples obstáculos, trepadoras y toboganes altos. IMPORTANTE Consumo eléctrico total del inflable: 24 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_128,
      carousel2_128,
      carousel3_128,
      carousel4_128,
      carousel5_128,
    ],
  },
  {
    id: 129,
    item: [36, 102],
    name: "CARRERA DESAFIO 5",
    image: principal_129,
    size: "4x13",
    age: "5-13 años",
    descripcion:
      "Carrera exclusiva y súper divertida con muchos obstáculos, doble tobogán, en dos módulos.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_129,
      carousel2_129,
      carousel3_129,
      carousel4_129,
      carousel5_129,
    ],
  },
  {
    id: 130,
    item: [34, 102],
    name: "CARRERA DESAFIO 6",
    image: principal_130,
    size: "5x13",
    age: "5-13 años",
    descripcion:
      "Carrera exclusiva y súper divertida con muchos obstáculos, doble tobogán, en dos módulos.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_130,
      carousel2_130,
      carousel3_130,
      carousel4_130,
      carousel5_130,
    ],
  },
  {
    id: 131,
    item: [26, 107],
    name: "CARRERA 11",
    image: principal_131,
    size: "4x13",
    age: "5-13 años",
    descripcion:
      "Carrera divertida con muchos obstáculos, doble tobogán, en dos módulos.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [principal_131, carousel2_131, carousel3_131],
  },
  {
    id: 132,
    item: [32, 101, 102, 104, 106],
    name: "SUPER CARRERA PRO 4",
    image: principal_132,
    size: "7x16",
    age: "5-13 años",
    descripcion:
      "Carrera de obstáculos en forma de U, con 40 metros de carrera continua. Dinámica de entrada y salida con poco trayecto fuera del inflable. IMPORTANTE Consumo eléctrico total del inflable: 30 amperes.",
    filtro: ["carreras", "grande", "5-9 años", "7-13 años"],
    carouselImages: [
      principal_132,
      carousel2_132,
      carousel3_132,
      carousel4_132,
      carousel5_132,
    ],
  },

  // ACUATICOS
  {
    id: 200,
    item: 6,
    name: "INFANTIL AL BORDE",
    image: principal_200,
    size: "2,50x4x2",
    age: "3-7 años",
    descripcion: "Con tobogán y punchi.",
    filtro: ["acuaticos", "chico", "2-7 años", "al borde"],
    carouselImages: [
      principal_200,
      carousel2_200,
      carousel3_200,
      carousel4_200,
      carousel5_200,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/GygRjFt48Bw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 201,
    item: 6,
    name: "INFANTIL CON PILETA",
    image: principal_201,
    size: "2,50x6x2",
    age: "2-7 años",
    descripcion:
      "Con tobogán, punchi y pileta inflable con decorado de palmeras.",
    filtro: ["acuaticos", "chico", "2-7 años", "con pileta"],
    carouselImages: [
      principal_201,
      carousel2_201,
      carousel3_201,
      carousel4_201,
      carousel5_201,
    ],
  },
  {
    id: 202,
    item: 7,
    name: "MAGICO AL BORDE",
    image: principal_202,
    size: "3x5x2,30",
    age: "3-8 años",
    descripcion: "Con tobogán y punchis.",
    filtro: ["acuaticos", "mediano", "2-7 años", "> 7 años", "al borde"],
    carouselImages: [
      principal_202,
      carousel2_202,
      carousel3_202,
      carousel4_202,
      carousel5_202,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/w6mY6dbJ6uA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 203,
    item: 7,
    name: "MAGICO CON PILETA",
    image: principal_203,
    size: "3x7x2,30",
    age: "2-8 años",
    descripcion: "Con tobogán, punchis y pileta inflable.",
    filtro: ["acuaticos", "mediano", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_203,
      carousel2_203,
      carousel3_203,
      carousel4_203,
      carousel5_203,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/1RXmbcOPUQk"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 204,
    item: 22,
    name: "DINO AL BORDE",
    image: principal_204,
    size: "4x4x3,50",
    age: "4-8 años",
    descripcion:
      "Área para saltar. Trepadora y tobogán con caída a la pileta. ",
    filtro: ["acuaticos", "mediano", "2-7 años", "> 7 años", "al borde"],
    carouselImages: [
      principal_204,
      carousel2_204,
      carousel3_204,
      carousel4_204,
      carousel5_204,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/EJIr4YP2SE4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 205,
    item: [22, 201],
    name: "DINO CON PILETA",
    image: principal_205,
    size: "4x7x3,50",
    age: "3-8 años",
    descripcion:
      "Área para saltar. Trepadora y tobogán con caída a la pileta inflable.",
    filtro: ["acuaticos", "mediano", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_205,
      carousel2_205,
      carousel3_205,
      carousel4_205,
      carousel5_205,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/sUrkTDwyqso"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 206,
    item: 32,
    name: "MURO 1 AL BORDE",
    image: principal_206,
    size: "3x6x4,50",
    age: "> 6 años",
    descripcion: "Con trepadora y tobogán alto. APTO ADULTOS.",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "al borde"],
    carouselImages: [
      principal_206,
      carousel2_206,
      carousel3_206,
      carousel4_206,
      carousel5_206,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/h4hPKgZ3FWY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 207,
    item: 32,
    name: "MURO 1 CON PILETA",
    image: principal_207,
    size: "3x8x4,50",
    age: "> 6 años",
    descripcion:
      "Con trepadora y tobogán alto, y pileta inflable incorporada con temática de delfines. APTO ADULTOS.",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_207,
      carousel2_207,
      carousel3_207,
      carousel4_207,
      carousel5_207,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/ZE1M4HklJ_8"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 208,
    item: 33,
    name: "MURO 2 AL BORDE",
    image: principal_208,
    size: "4x6x4,50",
    age: "> 6 años",
    descripcion: "Con trepadora y tobogán alto. APTO ADULTOS.",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "al borde"],
    carouselImages: [
      principal_208,
      carousel2_208,
      carousel3_208,
      carousel4_208,
      carousel5_208,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/0C_aRTMxfPQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 209,
    item: 33,
    name: "MURO 2 CON PILETA",
    image: principal_209,
    size: "4x9x4,50",
    age: "> 6 años",
    descripcion:
      "Con trepadora y tobogán alto, con pileta de agua incorporada. APTO ADULTOS.",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_209,
      carousel2_209,
      carousel3_209,
      carousel4_209,
      carousel5_209,
    ],
  },
  {
    id: 210,
    item: 26,
    name: "TREPADORA AL BORDE",
    image: principal_210,
    size: "3x7,50x2,70",
    age: "5-13 años",
    descripcion: "Con amplia área para saltar, túnel, repadora y tobogán.",
    filtro: ["acuaticos", "mediano", "2-7 años", "> 7 años", "al borde"],
    carouselImages: [
      principal_210,
      carousel2_210,
      carousel3_210,
      carousel4_210,
      carousel5_210,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/IAgcZGI166w"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 211,
    item: 26,
    name: "TREPADORA CON PILETA",
    image: principal_211,
    size: "3x10x2,70",
    age: "5-13 años",
    descripcion:
      "Con amplia área para saltar, túnel, repadora y tobogán. Con pileta decorada de palmeras y contención alta.",
    filtro: ["acuaticos", "mediano", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_211,
      carousel2_211,
      carousel3_211,
      carousel4_211,
      carousel5_211,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/dlAgo5Hq-Jc"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 212,
    item: 34,
    name: "SUPER ESCALADOR AL BORDE",
    image: principal_212,
    size: "6x5x5",
    age: "> 5 años",
    descripcion:
      "El inflable más alto, super imponente y divertido. Con doble ingreso, doble trepadora y tobogán alto y ancho. APTO ADULTOS.",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "al borde"],
    carouselImages: [
      principal_212,
      carousel2_212,
      carousel3_212,
      carousel4_212,
      carousel5_212,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/xizE0c902s0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 213,
    item: 34,
    name: "SUPER ESCALADOR CON PILETA",
    image: principal_213,
    size: "10x5x5",
    age: "> 5 años",
    descripcion:
      "El inflable más alto, super imponente y divertido. Con doble ingreso, doble trepadora y tobogán alto y ancho. Con pileta grande, con palmeras y contención alta. APTO ADULTOS.",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_213,
      carousel2_213,
      carousel3_213,
      carousel4_213,
      carousel5_213,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/vDMfLc2NnRE"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 214,
    item: [33, 103],
    name: "CARRERA 5 AL BORDE",
    image: principal_214,
    size: "12x4x4,50",
    age: "> 5 años",
    descripcion:
      "Carrera de obstáculos acuática, super original y divertida. Con muchos obstáculos, trepador y tobogán a la pileta. APTO ADULTOS.",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "al borde"],
    carouselImages: [
      principal_214,
      carousel2_214,
      carousel3_214,
      carousel4_214,
      carousel5_214,
    ],
  },
  {
    id: 215,
    item: [33, 103],
    name: "CARRERA 5 CON PILETA",
    image: principal_215,
    size: "15x4x4,50",
    age: "> 5 años",
    descripcion:
      "Carrera de obstáculos acuática, super original y divertida. Con muchos obstáculos, trepador y tobogán con caída a la pileta inflable. APTO ADULTOS.",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_215,
      carousel2_215,
      carousel3_215,
      carousel4_215,
      carousel5_215,
    ],
  },
  {
    id: 216,
    item: 37,
    name: "MUNDO MARINO AL BORDE",
    image: principal_216,
    size: "5x5,50x4",
    age: "> 6 años",
    descripcion:
      "Con muro y tobogán, con temática marina, incluye Nemo, hipocampo y dibujos varios. APTO ADULTOS.",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "al borde"],
    carouselImages: [
      principal_216,
      carousel2_216,
      carousel3_216,
      carousel4_216,
      carousel5_216,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/NZw1IFgnIr0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 217,
    item: [37, 204],
    name: "MUNDO MARINO CON PILETA",
    image: principal_217,
    size: "5x8,50x4",
    age: "> 6 años",
    descripcion:
      "Con muro y tobogán, con temática marina, incluye Nemo, hipocampo y dibujos varios. Incluye pileta inflable con forma de tortuga. APTO ADULTOS.",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_217,
      carousel2_217,
      carousel3_217,
      carousel4_217,
      carousel5_217,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/LB60OhexS48"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 218,
    item: 35,
    name: "MULTICOLOR AL BORDE",
    image: principal_218,
    size: "3x5x3,50",
    age: "6-13 años",
    descripcion: "Trepadora y tobogán alto.",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "al borde"],
    carouselImages: [
      principal_218,
      carousel2_218,
      carousel3_218,
      carousel4_218,
      carousel5_218,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/cXtUWpfFZZI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 219,
    item: 35,
    name: "MULTICOLOR CON PILETA",
    image: principal_219,
    size: "3x7x3,50",
    age: "6-13 años",
    descripcion: "Trepadora y tobogán alto, con pileta inflable.",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_219,
      carousel2_219,
      carousel3_219,
      carousel4_219,
      carousel5_219,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/Twfc08bKn0o"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 220,
    item: 36,
    name: "SURF AL BORDE",
    image: principal_220,
    size: "6,20x4x4,50",
    age: "> 6 años",
    descripcion:
      "Con trepadora, tobogán alto y decorado con tablas de surf y nenes surfeando en los laterales. APTO ADULTOS",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "al borde"],
    carouselImages: [
      principal_220,
      carousel2_220,
      carousel3_220,
      carousel4_220,
      carousel5_220,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/zwyXJ9N7cgQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 221,
    item: 36,
    name: "SURF CON PILETA",
    image: principal_221,
    size: "9x4x4,50",
    age: "> 6 años",
    descripcion:
      "Con trepadora, tobogán alto y decorado con tablas de surf y nenes surfeando en los laterales. Con amplia pileta y contención alta. APTO ADULTOS",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_221,
      carousel2_221,
      carousel3_221,
      carousel4_221,
      carousel5_221,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/YDoiSMA76zM"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 222,
    item: [202, 38],
    name: "AVENTURA 1 CON PILETA",
    image: principal_222,
    size: "6x9x4",
    age: "5-13 años",
    descripcion: "Con doble tobogán: curvo y recto. Con splash incorporado. ",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_222,
      carousel2_222,
      carousel3_222,
      carousel4_222,
      carousel5_222,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/rkKKyLCWMdk"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 223,
    item: 39,
    name: "AVENTURA 2 AL BORDE",
    image: principal_223,
    size: "6x5x4,50",
    age: "> 5 años",
    descripcion: "Con doble tobogán: curvo y recto. APTO ADULTOS.",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "al borde"],
    carouselImages: [
      principal_223,
      carousel2_223,
      carousel3_223,
      carousel4_223,
      carousel5_223,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/JoZrjGZnw28"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 224,
    item: 39,
    name: "AVENTURA 2 CON PILETA",
    image: principal_224,
    size: "6x9x4,50",
    age: "> 5 años",
    descripcion: "Con doble tobogán: curvo y recto. APTO ADULTOS.",
    filtro: ["acuaticos", "grande", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_224,
      carousel2_224,
      carousel3_224,
      carousel4_224,
      carousel5_224,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/iz5liDjbxCg"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 225,
    item: 202,
    name: "SPLASH 1 AL BORDE",
    image: principal_225,
    size: "6,50x1,50",
    age: "6-13 años",
    descripcion: "Deslizador de agua inflable, carril simple.",
    filtro: ["acuaticos", "mediano", "2-7 años", "> 7 años", "al borde"],
    carouselImages: [
      principal_225,
      carousel2_225,
      carousel3_225,
      carousel4_225,
      carousel5_225,
    ],
  },
  {
    id: 226,
    item: [202, 201],
    name: "SPLASH 1 CON PILETA",
    image: principal_226,
    size: "8x1,50",
    age: "6-13 años",
    descripcion:
      "Deslizador de agua inflable, carril simple. Con pileta inflable.",
    filtro: ["acuaticos", "mediano", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_226,
      carousel2_226,
      carousel3_226,
      carousel4_226,
      carousel5_226,
    ],
  },
  {
    id: 227,
    item: 203,
    name: "SPLASH DOBLE AL BORDE",
    image: principal_227,
    size: "6,50x2,50",
    age: "> 6 años",
    descripcion:
      "Novedoso deslizador de agua inflable, doble carril. Ideal para hacer competencias y carreras. APTO ADULTOS.",
    filtro: ["acuaticos", "mediano", "2-7 años", "> 7 años", "al borde"],
    carouselImages: [
      principal_227,
      carousel2_227,
      carousel3_227,
      carousel4_227,
      carousel5_227,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/JZJNcfL0Pws"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 228,
    item: 203,
    name: "SPLASH DOBLE CON PILETA",
    image: principal_228,
    size: "9x2,50",
    age: "> 6 años",
    descripcion:
      "Novedoso deslizador de agua inflable, doble carril. Ideal para hacer competencias y carreras. Incluye pileta inflable con protección alta APTO ADULTOS.",
    filtro: ["acuaticos", "mediano", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_228,
      carousel2_228,
      carousel3_228,
      carousel4_228,
      carousel5_228,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://youtube.com/embed/fKJK5vdGGx4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 229,
    item: [43, 204],
    name: "SAFARI CON TORTUGA PELOTERO",
    image: principal_229,
    size: "4x5,50x5",
    age: "2-7 años",
    descripcion:
      "Área para saltar, trepadora y tobogán. Ambientado con temática de safari y pileta acuática con forma de tortuga.",
    filtro: ["acuaticos", "mediano", "2-7 años", "con pileta"],
    carouselImages: [
      principal_229,
      carousel2_229,
      carousel3_229,
      carousel4_229,
      carousel5_229,
    ],
  },
  {
    id: 230,
    item: 107,
    name: "CARRERA 10 AL BORDE",
    image: principal_230,
    size: "4x7",
    age: "> 7 años",
    descripcion:
      "Carrera de obstáculos acuática, con múltiples obstáculos. APTO ADULTOS.",
    filtro: ["acuaticos", "mediano", "7-13 años", "> 7 años", "con pileta"],
    carouselImages: [principal_230, carousel2_230, carousel3_230],
  },
  {
    id: 231,
    item: 107,
    name: "CARRERA 10 CON PILETA",
    image: principal_231,
    size: "4x10",
    age: "> 7 años",
    descripcion:
      "Carrera de obstáculos acuática, con múltiples obstáculos y pileta inflable. APTO ADULTOS.",
    filtro: ["acuaticos", "mediano", "2-7 años", "> 7 años", "con pileta"],
    carouselImages: [
      principal_231,
      carousel2_231,
      carousel3_231,
      carousel4_231,
    ],
  },
  // ----------------- KERMESSE -----------------------
  {
    id: 300,
    item: 301,
    name: "TIRO AL BLANCO",
    image: principal_300,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Hay que embocar el dardo de abrojo en la lona, lo más cercano al centro posible, sumas mas puntos. Ideal para jugar competencias. APTO ADULTOS.",
    filtro: ["kermesse"],
    carouselImages: [
      principal_300,
      carousel2_300,
      carousel3_300,
      carousel4_300,
      carousel5_300,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/xM4TNiwwiBQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 301,
    item: 302,
    name: "CARRERA FLOTADORA ",
    image: principal_301,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Juego de competencia con dos participantes que consiste en pasar la pelotita entre los conos que tiene una abertura que larga aire, lo cual hace que esta flote, y avanzar hasta el extremo del juego hasta embocar la pelotita en el aro. Ideal para jugar competencias. APTO ADULTOS.",
    filtro: ["kermesse"],
    carouselImages: [
      principal_301,
      carousel2_301,
      carousel3_301,
      carousel4_301,
      carousel5_301,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/VPw7BZMmfz0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 302,
    item: 303,
    name: "BASQUET",
    image: principal_302,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Son dos aros. Competencia entre dos participantes, quien más rápido emboque la pelota gana. Ideal para jugar competencias. APTO ADULTOS.",
    filtro: ["kermesse"],
    carouselImages: [
      principal_302,
      carousel2_302,
      carousel3_302,
      carousel4_302,
      carousel5_302,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/xsFkC9-kY5U"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 303,
    item: 304,
    name: "PELOTITAS FLOTADORAS ",
    image: principal_303,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Se ponen 5 pelotitas sobre el inflable que van a estar flotando por donde sale aire. El objetivo es derribarlas con una pelotita. Ideal para jugar competencias. APTO ADULTOS.",
    filtro: ["kermesse"],
    carouselImages: [
      principal_303,
      carousel2_303,
      carousel3_303,
      carousel4_303,
      carousel5_303,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/kYSdKRawfBg"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 304,
    item: 305,
    name: "SAPO",
    image: principal_304,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Similar al sapo tradicional, pero en version inflable. Hay que embocar la pelotita en los agujeros del inflable. Ideal para jugar competencias. APTO ADULTOS.",
    filtro: ["kermesse"],
    carouselImages: [
      principal_304,
      carousel2_304,
      carousel3_304,
      carousel4_304,
      carousel5_304,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/iIT7sXfcK1E"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 305,
    item: 306,
    name: "MONITO",
    image: principal_305,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Embocar la pelotita en los agujeros del panel frontal del inflable. Ideal para jugar competencias. APTO ADULTOS.",
    filtro: ["kermesse"],
    carouselImages: [
      principal_305,
      carousel2_305,
      carousel3_305,
      carousel4_305,
      carousel5_305,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/6PPHMHg5jAo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 306,
    item: 307,
    name: "EMBOQUE",
    image: principal_306,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Embocar en los agujeros de la lona frontal del inflable. Embocá, sumá puntos y ganá! Ideal para jugar competencias. APTO ADULTOS.",
    filtro: ["kermesse"],
    carouselImages: [
      principal_306,
      carousel2_306,
      carousel3_306,
      carousel4_306,
      carousel5_306,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/lTahfGDBslE"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 307,
    item: 308,
    name: "MULTIJUEGOS",
    image: principal_307,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Son 4 juegos en 1: aro de basquet, embocar corchos en la lona frontal, apoyar fichas en los círculos de la lona inferior y embocar aros en los conos. Ideal para jugar competencias. APTO ADULTOS.",
    filtro: ["kermesse"],
    carouselImages: [
      principal_307,
      carousel2_307,
      carousel3_307,
      carousel4_307,
      carousel5_307,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/cZ3NYGMvPMo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 308,
    item: 309,
    name: "KERMESSE COMPLETA",
    image: principal_308,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Kermesse completa con animación, 3 horas, 3 animadores, traslado, ambientación, 12 puestos y kiosko. Ideal para jugar competencias. APTO ADULTOS.",
    filtro: ["kermesse"],
    carouselImages: [
      principal_308,
      carousel2_308,
      carousel3_308,
      carousel4_308,
      carousel5_308,
    ],
    consultar: true,
  },

  // Mini Livings
  {
    id: 400,
    item: 401,
    name: "COLORIDA 1 ROJO",
    image: principal_400,
    size: "-",
    age: "1-10 años",
    descripcion: "10 mini puff y 1 mesa roja 1,20 x 0,65.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      principal_400,
      carousel2_400,
      carousel3_400,
      carousel4_400,
      carousel5_400,
    ],
  },
  {
    id: 401,
    item: 402,
    name: "COLORIDA 1 VIOLETA",
    image: principal_401,
    size: "-",
    age: "1-10 años",
    descripcion: "10 mini puff y 1 mesa violeta 1,20 x 0,65.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      principal_401,
      carousel2_401,
      carousel3_401,
      carousel4_401,
      carousel5_401,
    ],
  },
  {
    id: 402,
    item: [401, 402],
    name: "COLORIDA 1 PLUS",
    image: principal_402,
    size: "-",
    age: "1-10 años",
    descripcion: "20 mini puff y 2 mesas roja y violeta 1,20 x 0,65.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      principal_402,
      carousel2_402,
      carousel3_402,
      carousel4_402,
      carousel5_402,
    ],
  },
  {
    id: 403,
    item: 403,
    name: "COLORIDA 2 ROJO",
    image: principal_403,
    size: "-",
    age: "1-10 años",
    descripcion: "10 mini puff y 1 mesa roja 1,20 x 0,70.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      principal_403,
      carousel2_403,
      carousel3_403,
      carousel4_403,
      carousel5_403,
    ],
  },
  {
    id: 404,
    item: 404,
    name: "COLORIDA 2 VERDE",
    image: principal_404,
    size: "-",
    age: "1-10 años",
    descripcion: "10 mini puff y 1 mesa verde 1,20 x 0,70.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      principal_404,
      carousel2_404,
      carousel3_404,
      carousel4_404,
      carousel5_404,
    ],
  },
  {
    id: 405,
    item: [403, 404],
    name: "COLORIDA 2 PLUS",
    image: principal_405,
    size: "-",
    age: "1-10 años",
    descripcion: "20 mini puff y 2 mesas roja y verde 1,20 x 0,70.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      principal_405,
      carousel2_405,
      carousel3_405,
      carousel4_405,
      carousel5_405,
    ],
  },
  {
    id: 406,
    item: 405,
    name: "IRREGULAR AZUL",
    image: principal_406,
    size: "-",
    age: "1-10 años",
    descripcion: "10 mini puff y 1 mesa azul 1,30 x 0,55.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      principal_406,
      carousel2_406,
      carousel3_406,
      carousel4_406,
      carousel5_406,
    ],
  },
  {
    id: 407,
    item: 406,
    name: "IRREGULAR ROSA",
    image: principal_407,
    size: "-",
    age: "1-10 años",
    descripcion: "10 mini puff y 1 mesa rosa 1,30 x 0,55.",
    filtro: ["livings", "miniliving"],
    carouselImages: [principal_407, carousel2_407, carousel3_407],
  },
  {
    id: 408,
    item: [405, 406],
    name: "IRREGULAR  PLUS",
    image: principal_408,
    size: "-",
    age: "1-10 años",
    descripcion: "20 mini puff y 2 mesas azul y rosa 1,30 x 0,55.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      principal_408,
      carousel2_408,
      carousel3_408,
      carousel4_408,
      carousel5_408,
    ],
  },
  {
    id: 409,
    item: 407,
    name: "MINILIVING BLANCO (para 10)",
    image: principal_409,
    size: "-",
    age: "1-10 años",
    descripcion: "10 mini puff y 1 mesa blanca 1,20 x 0,70.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      principal_409,
      carousel2_409,
      carousel3_409,
      carousel4_409,
      carousel5_409,
    ],
  },
  {
    id: 410,
    item: 408,
    name: "MINILIVING BLANCO (para 20)",
    image: principal_410,
    size: "-",
    age: "1-10 años",
    descripcion: "20 mini puff y 2 mesas blancas 1,20 x 0,70.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      principal_410,
      carousel2_410,
      carousel3_410,
      carousel4_410,
      carousel5_410,
    ],
  },
  // {
  //   id: 411,
  //   item: 409,
  //   name: "MINILIVING BLANCO (30)",
  //   image: principal_blank,
  //   size: "-",
  //   age: "1-10 años",
  //   descripcion: "30 mini puff y 3 mesas blancas 1,20 x 0,70.",
  //   filtro: ["livings","miniliving"],
  //   carouselImages: [
  //     principal_blank,
  //     carousel2_blank,
  //     carousel3_blank,
  //     carousel4_blank,
  //     carousel5_blank,
  //   ],
  // },
  // {
  //   id: 412,
  //   item: [407, 459],
  //   name: "MINILIVING BLANCO (10) + GAZEBO",
  //   image: principal_blank,
  //   size: "-",
  //   age: "1-10 años",
  //   descripcion: "10 mini puff, 1 mesa blancas 1,20 x 0,70 y 1 gazebo blanco.",
  //   filtro: ["livings","miniliving"],
  //   carouselImages: [
  //     principal_blank,
  //     carousel2_blank,
  //     carousel3_blank,
  //     carousel4_blank,
  //     carousel5_blank,
  //   ],
  // },
  {
    id: 413,
    item: [408, 458],
    name: "MINILIVING BLANCO (para 20) + GAZEBO",
    image: principal_413,
    size: "-",
    age: "1-10 años",
    descripcion: "20 mini puff, 2 mesas blancas 1,20 x 0,70 y 1 gazebo blanco.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      principal_413,
      carousel2_413,
      carousel3_413,
      carousel4_413,
      carousel5_413,
    ],
  },
  {
    id: 414,
    item: 410,
    name: "MESAS Y SILLITAS (para 10)",
    image: principal_414,
    size: "-",
    age: "-",
    descripcion: "10 sillitas, 2 mesas blancas 1,20 x 0,60.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      principal_414,
      carousel2_414,
      carousel3_414,
      carousel4_414,
      carousel5_414,
    ],
    consultar: true,
  },
  {
    id: 415,
    item: 411,
    name: "MESAS Y SILLITAS (para 20)",
    image: principal_415,
    size: "-",
    age: "-",
    descripcion: "20 sillitas, 4 mesas blancas 1,20 x 0,60.",
    filtro: ["livings", "miniliving"],
    carouselImages: [
      principal_415,
      carousel2_415,
      carousel3_415,
      carousel4_415,
      carousel5_415,
    ],
    consultar: true,
  },

  // Livings
  {
    id: 450,
    item: 451,
    name: "LIVING SIMPLE (10)",
    image: principal_450,
    size: "-",
    age: "-",
    descripcion: "10 puff cuadrados y redondos + 1 mesa blanca 1,20 x 0,70. ",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_450,
      carousel2_450,
      carousel3_450,
      carousel4_450,
      carousel5_450,
    ],
  },

  {
    id: 451,
    item: 452,
    name: "LIVING CON BUTACON (10)",
    image: principal_451,
    size: "-",
    age: "-",
    descripcion:
      "Living para 10 personas con butacón, puff cuadrados y redondos + 1 mesa blanca 1,20 x 0,70. ",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_451,
      carousel2_451,
      carousel3_451,
      carousel4_451,
      carousel5_451,
    ],
  },

  {
    id: 452,
    item: 453,
    name: "LIVING CHOCOLATE (10)",
    image: principal_452,
    size: "-",
    age: "-",
    descripcion:
      "10 puff redondos color chocolate + 1 mesa blanca 1,20 x 0,70. ",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_452,
      carousel2_452,
      carousel3_452,
      carousel4_452,
      carousel5_452,
    ],
  },

  {
    id: 453,
    item: 454,
    name: "LIVING SIMPLE (20)",
    image: principal_453,
    size: "-",
    age: "-",
    descripcion: "20 puff cuadrados y redondos + 2 mesas blancas 1,20 x 0,70.",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_453,
      carousel2_453,
      carousel3_453,
      carousel4_453,
      carousel5_453,
    ],
  },

  {
    id: 454,
    item: 455,
    name: "LIVING CON BUTACON (20)",
    image: principal_454,
    size: "-",
    age: "-",
    descripcion:
      "Living para 20 personas con 2 butacones, puff cuadrados y redondos + 2 mesas blancas 1,20 x 0,70. ",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_454,
      carousel2_454,
      carousel3_454,
      carousel4_454,
      carousel5_454,
    ],
  },

  {
    id: 455,
    item: 456,
    name: "LIVING SIMPLE (30)",
    image: principal_455,
    size: "-",
    age: "-",
    descripcion: "30 puff cuadrados y redondos + 3 mesas blancas 1,20 x 0,70. ",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_455,
      carousel2_455,
      carousel3_455,
      carousel4_455,
      carousel5_455,
    ],
  },

  {
    id: 456,
    item: 455,
    name: "LIVING CON BUTACON (30)",
    image: principal_456,
    size: "-",
    age: "-",
    descripcion:
      "Living para 30 personas con 3 butacones, puff cuadrados y redondos + 3 mesas blancas 1,20 x 0,70. ",
    filtro: ["livings", "living"],
    carouselImages: [principal_456, carousel2_456, carousel3_456],
  },

  {
    id: 457,
    item: 457,
    name: "GAZEBO BLANCO",
    image: principal_457,
    size: "3x3",
    age: "-",
    descripcion: "Sin laterales, 3x3.",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_457,
      carousel2_457,
      carousel3_457,
      carousel4_457,
      carousel5_457,
    ],
  },

  {
    id: 458,
    item: 458,
    name: "GAZEBO BLANCO",
    image: principal_457,
    size: "3x3",
    age: "-",
    descripcion: "Sin laterales, 3x3.",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_457,
      carousel2_457,
      carousel3_457,
      carousel4_457,
      carousel5_457,
    ],
  },

  {
    id: 459,
    item: 459,
    name: "GAZEBO BLANCO",
    image: principal_457,
    size: "3x3",
    age: "-",
    descripcion: "Sin laterales, 3x3.",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_457,
      carousel2_457,
      carousel3_457,
      carousel4_457,
      carousel5_457,
    ],
  },

  {
    id: 460,
    item: 460,
    name: "GAZEBO AZUL",
    image: principal_460,
    size: "3x3",
    age: "-",
    descripcion: "Sin laterales, 3x3.",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_460,
      carousel2_460,
      carousel3_460,
      carousel4_460,
      carousel5_460,
    ],
  },

  {
    id: 461,
    item: 461,
    name: "GAZEBO AZUL",
    image: principal_460,
    size: "3x3",
    age: "-",
    descripcion: "Sin laterales, 3x3.",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_460,
      carousel2_460,
      carousel3_460,
      carousel4_460,
      carousel5_460,
    ],
  },

  {
    id: 462,
    item: 462,
    name: "GAZEBO AZUL",
    image: principal_460,
    size: "3x3",
    age: "-",
    descripcion: "Sin laterales, 3x3.",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_460,
      carousel2_460,
      carousel3_460,
      carousel4_460,
      carousel5_460,
    ],
  },
  {
    id: 463,
    item: 463,
    name: "LIVING BLANCO/CHOCOLATE (10)",
    image: principal_463,
    size: "-",
    age: "-",
    descripcion:
      "10 puff redondos color chocolate y blanco + 1 mesa blanca 1,20 x 0,70. ",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_463,
      carousel2_463,
      carousel3_463,
      carousel4_463,
      carousel5_463,
    ],
  },
  {
    id: 464,
    item: 453,
    name: "LIVING BLANCO/CHOCOLATE (20)",
    image: principal_464,
    size: "-",
    age: "-",
    descripcion:
      "20 puff redondos color chocolate y blanco + 2 mesas blancas 1,20 x 0,70. ",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_464,
      carousel2_464,
      carousel3_464,
      carousel4_464,
      carousel5_464,
    ],
  },
  {
    id: 465,
    item: [453, 463],
    name: "LIVING BLANCO/CHOCOLATE (30)",
    image: principal_465,
    size: "-",
    age: "-",
    descripcion:
      "30 puff redondos color chocolate y blanco + 3 mesas blancas 1,20 x 0,70. ",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_465,
      carousel2_465,
      carousel3_465,
      carousel4_465,
      carousel5_465,
    ],
  },
  {
    id: 466,
    item: 466,
    name: "LIVING (10) + GAZEBO ",
    image: principal_465,
    size: "-",
    age: "-",
    descripcion:
      "Living para 10 personas con butacón, puff cuadrados + 1 mesa blanca 1,20 x 0,70 + 1 Gazebo.",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_466,
      carousel2_466,
      carousel3_466,
      carousel4_466,
    ],
  },
  {
    id: 467,
    item: 467,
    name: "LIVING (20) + GAZEBO",
    image: principal_467,
    size: "-",
    age: "-",
    descripcion:
      "Living para 20 personas con 2 butacón, puff cuadrados + 2 mesas blancas 1,20 x 0,70 + 1 Gazebo. ",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_467,
      carousel2_467,
      carousel3_467,
      carousel4_467,
      carousel5_467,
    ],
  },
  // {
  //   id: 468,
  //   item: 468,
  //   name: "LIVING (30) + 2 GAZEBOS",
  //   image: principal_blank,
  //   size: "-",
  //   age: "-",
  //   descripcion: "Living para 30 personas con 3 butacones, puff cuadrados + 3 mesas blancas 1,20 x 0,70 + 2 Gazebos. ",
  //   filtro: ["livings","living"],
  //   carouselImages: [
  //     principal_blank,
  //     carousel2_blank,
  //     carousel3_blank,
  //     carousel4_blank,
  //     carousel5_blank,
  //   ],
  // },
  {
    id: 469,
    item: 469,
    name: "LIVING (40) + 2 GAZEBOS ",
    image: principal_469,
    size: "-",
    age: "-",
    descripcion:
      "Living para 40 personas con 4 butacones, puff cuadrados + 4 mesas blancas 1,20 x 0,70 + 2 Gazebos.",
    filtro: ["livings", "living"],
    carouselImages: [
      principal_469,
      carousel2_469,
      carousel3_469,
      carousel4_469,
      carousel5_469,
    ],
  },
  // ----------------------- JUEGOS ------------------------------
  {
    id: 500,
    item: 501,
    name: "METEGOL",
    image: principal_501,
    size: "1,40 x 0,70",
    age: "> 5 años",
    descripcion: "Incluye una pelotita. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_501,
      carousel2_501,
      carousel3_501,
      carousel4_501,
      carousel5_501,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/azu1K0vhFdU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 501,
    item: 502,
    name: "METEGOL",
    image: principal_501,
    size: "1,40 x 0,70",
    age: "> 5 años",
    descripcion: "Incluye una pelotita. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_501,
      carousel2_501,
      carousel3_501,
      carousel4_501,
      carousel5_501,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/azu1K0vhFdU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 502,
    item: 503,
    name: "METEGOL",
    image: principal_501,
    size: "1,40 x 0,70",
    age: "> 5 años",
    descripcion: "Incluye una pelotita. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_501,
      carousel2_501,
      carousel3_501,
      carousel4_501,
      carousel5_501,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/azu1K0vhFdU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 503,
    item: 504,
    name: "METEGOL",
    image: principal_501,
    size: "1,40 x 0,70",
    age: "> 5 años",
    descripcion: "Incluye una pelotita. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_501,
      carousel2_501,
      carousel3_501,
      carousel4_501,
      carousel5_501,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/azu1K0vhFdU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 504,
    item: 505,
    name: "MESA DE TEJO",
    image: principal_504,
    size: "1.70 x 1",
    age: "> 5 años",
    descripcion: "Incluye dos manoplas y un disco. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_504,
      carousel2_504,
      carousel3_504,
      carousel4_504,
      carousel5_504,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/5mB6s9rTTy4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 505,
    item: 506,
    name: "MESA DE TEJO",
    image: principal_504,
    size: "1,70 x 1",
    age: "> 5 años",
    descripcion: "Incluye dos manoplas y un disco. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_504,
      carousel2_504,
      carousel3_504,
      carousel4_504,
      carousel5_504,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/5mB6s9rTTy4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 506,
    item: 507,
    name: "MESA DE TEJO",
    image: principal_504,
    size: "1,70 x 1",
    age: "> 5 años",
    descripcion: "Incluye dos manoplas y un disco. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_504,
      carousel2_504,
      carousel3_504,
      carousel4_504,
      carousel5_504,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/5mB6s9rTTy4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 507,
    item: 508,
    name: "MESA DE POOL MEDIANA",
    image: principal_507,
    size: "1,70 x 1 ",
    age: "> 5 años",
    descripcion:
      "Incluye 2 tacos, 1 bola blanca, 15 bolas de colores y 1 triángulo. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_507,
      carousel2_507,
      carousel3_507,
      carousel4_507,
      carousel5_507,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/GVJ0JOclc6A"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 508,
    item: 509,
    name: "POOL SEMI PROFESIONAL",
    image: principal_508,
    size: "1,85 x 1,10",
    age: "> 5 años",
    descripcion:
      "Incluye 2 tacos, 1 bola blanca, 15 bolas de colores y 1 triángulo. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_508,
      carousel2_508,
      carousel3_508,
      carousel4_508,
      carousel5_508,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/oj3nf9fIh-4"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 509,
    item: 510,
    name: "MESA DE PING PONG MEDIANA",
    image: principal_509,
    size: "2,40 x 1,20",
    age: "> 5 años",
    descripcion:
      "Mesa de ping pong mediana. Incluye 4 paletas, 2 pelotitas y 1 red. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_509,
      carousel2_509,
      carousel3_509,
      carousel4_509,
      carousel5_509,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/25jQFLp-FaA"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 510,
    item: 511,
    name: "MESA DE PING PONG PROF.",
    image: principal_510,
    size: "2,74 x 1,52",
    age: "> 5 años",
    descripcion:
      "Mesa de ping pong profesional. Incluye 4 paletas, 2 pelotitas y 1 red. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_510,
      carousel2_510,
      carousel3_510,
      carousel4_510,
      carousel5_510,
    ],
  },
  {
    id: 511,
    item: 512,
    name: "JENGA MEDIANO",
    image: principal_511,
    size: "45 cm",
    age: "> 5 años",
    descripcion: "45 cm alto. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_511,
      carousel2_511,
      carousel3_511,
      carousel4_511,
      carousel5_511,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/y9YAjStlD8E"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 512,
    item: 513,
    name: "JENGA MEDIANO",
    image: principal_511,
    size: "45 cm",
    age: "> 5 años",
    descripcion: "45 cm alto. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_511,
      carousel2_511,
      carousel3_511,
      carousel4_511,
      carousel5_511,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/y9YAjStlD8E"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 513,
    item: 514,
    name: "JENGA GRANDE",
    image: principal_513,
    size: "90 cm",
    age: "> 5 años",
    descripcion: "90 cm de alto con base de madera. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_513,
      carousel2_513,
      carousel3_513,
      carousel4_513,
      carousel5_513,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/w4OV0IiWpcs"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 514,
    item: 515,
    name: "PASAFICHAS",
    image: principal_514,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Juego muy divertido e innovador. Se juega con 2 participantes, y gana quien se quede sin fichas de su lado. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_514,
      carousel2_514,
      carousel3_514,
      carousel4_514,
      carousel5_514,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/dsIdgS69gTo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 515,
    item: 516,
    name: "PASAFICHAS",
    image: principal_514,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Juego muy divertido e innovador. Se juega con 2 participantes, y gana quien se quede sin fichas de su lado. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_514,
      carousel2_514,
      carousel3_514,
      carousel4_514,
      carousel5_514,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/dsIdgS69gTo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 516,
    item: 517,
    name: "PASAFICHAS",
    image: principal_514,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Juego muy divertido e innovador. Se juega con 2 participantes, y gana quien se quede sin fichas de su lado. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_514,
      carousel2_514,
      carousel3_514,
      carousel4_514,
      carousel5_514,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/dsIdgS69gTo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 517,
    item: 518,
    name: "PASAFICHAS DOBLE GRANDE",
    image: principal_517,
    size: "1 x 1,20",
    age: "> 5 años",
    descripcion:
      "Juego muy divertido e innovador. Se juega con 4 participantes, y gana quien se quede sin fichas de su lado. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_517,
      carousel2_517,
      carousel3_517,
      carousel4_517,
      carousel5_517,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/-LbU67XlPgo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 518,
    item: 519,
    name: "PASAFICHAS DOBLE GRANDE",
    image: principal_517,
    size: "1 x 1,20",
    age: "> 5 años",
    descripcion:
      "Juego muy divertido e innovador. Se juega con 4 participantes, y gana quien se quede sin fichas de su lado. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_517,
      carousel2_517,
      carousel3_517,
      carousel4_517,
      carousel5_517,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/-LbU67XlPgo"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 519,
    item: 520,
    name: "JENGA GRANDE",
    image: principal_513,
    size: "90 cm",
    age: "> 5 años",
    descripcion: "90 cm de alto con base de madera. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_513,
      carousel2_513,
      carousel3_513,
      carousel4_513,
      carousel5_513,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/w4OV0IiWpcs"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 520,
    item: [526, 527],
    name: "JUEGO DE PUNTERÍA DOBLE",
    image: principal_520,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Novedad Cornhole. Incluye 8 bolsitas, gana el que suma más puntos embocando en el agujero. Se juega con dos tablas enfrentadas. Ver video explicativo. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_520,
      carousel2_520,
      carousel3_520,
      carousel4_520,
      carousel5_520,
    ],
  },
  {
    id: 521,
    item: 522,
    name: "CANCHA FUTBOL TENIS",
    image: principal_521,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Incluye red y lineas perimetrales. No incluye pelota. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_521,
      carousel2_521,
      carousel3_521,
      carousel4_521,
      carousel5_521,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/9dmOjfnexow"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 522,
    item: 523,
    name: "CANCHA DE VOLEY",
    image: principal_522,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Incluye red y lineas perimetrales. Incluye pelota. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_522,
      carousel2_522,
      carousel3_522,
      carousel4_522,
    ],
  },
  {
    id: 523,
    item: 524,
    name: "ARCOS DE FUTBOL",
    image: principal_523,
    size: "-",
    age: "> 5 años",
    descripcion:
      "2 arcos de futbol 1,40 x 1,70. No incluye pelota. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_523,
      carousel2_523,
      carousel3_523,
      carousel4_523,
      carousel5_523,
    ],
    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/ZUsR-Rk-Cns"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 524,
    item: 525,
    name: "JUEGO DE PUNTERÍA",
    image: principal_524,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Novedad Cornhole. Incluye 8 bolsitas, gana el que suma más puntos embocando en el agujero. Ver video explicativo. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_524,
      carousel2_524,
      carousel3_524,
      carousel4_524,
      carousel5_524,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/74d0Ik2CiMY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 525,
    item: 526,
    name: "JUEGO DE PUNTERÍA",
    image: principal_524,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Novedad Cornhole. Incluye 8 bolsitas, gana el que suma más puntos embocando en el agujero. Ver video explicativo. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_524,
      carousel2_524,
      carousel3_524,
      carousel4_524,
      carousel5_524,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/74d0Ik2CiMY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  {
    id: 526,
    item: 527,
    name: "JUEGO DE PUNTERÍA",
    image: principal_524,
    size: "-",
    age: "> 5 años",
    descripcion:
      "Novedad Cornhole. Incluye 8 bolsitas, gana el que suma más puntos embocando en el agujero. Ver video explicativo. APTO ADULTOS.",
    filtro: ["juegos"],
    carouselImages: [
      principal_524,
      carousel2_524,
      carousel3_524,
      carousel4_524,
      carousel5_524,
    ],

    video: (
      <iframe
        className="carousel-image-grande"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/74d0Ik2CiMY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    ),
  },
  // --------------------- PLAZA BLANDA --------------------------
  {
    id: 600,
    item: 601,
    name: "DIDACTICO 1",
    image: principal_600,
    size: "-",
    age: "2-5 años",
    descripcion:
      ".+ 4 pisos de goma eva 1x1 + 1 pizarrón doble panel + 1 sube y baja + 1 perrito saltarín + 1 tobogán + 1 mesa con juegos didácticos de madera o imantados + 2 minipuff",
    filtro: ["plaza blanda"],
    carouselImages: [
      principal_600,
      carousel2_600,
      carousel3_600,
      carousel4_600,
      carousel5_600,
    ],
  },
  {
    id: 601,
    item: 602,
    name: "DIDACTICO 2",
    image: principal_601,
    size: "-",
    age: "2-5 años",
    descripcion:
      ".+ 4 pisos de goma eva 1x1 + 1 pelotero 1x1 + 1 pizarrón doble panel + 1 sube y baja + 1 perrito inflable + 1 dino saltarín + 1 tobogán + 1 mesa con 4 juegos imantados + 1 mesa con cubo de 5 actividades + cubo multijuego de 4 juegos y prono",
    filtro: ["plaza blanda"],
    carouselImages: [
      principal_601,
      carousel2_601,
      carousel3_601,
      carousel4_601,
      carousel5_601,
    ],
  },
  {
    id: 602,
    item: 603,
    name: "PLAZA KIDS",
    image: principal_602,
    size: "-",
    age: "2-5 años",
    descripcion:
      ".+ 4 pisos de goma eva + 1 pelotero 1x1 + 1 pizarrón doble panel + 1 sube y baja + 2 zapatillas andadoras + 1 perrito inflable + 1 dino saltarín + 1 tobogán + 1 mesa con juegos didácticos + 1 mesa con cubo de 5 actividades + cubo multijuego de 4 juegos y prono + gazebo bonificado si se arma al aire libre (no intercambiable por otro articulo)",
    filtro: ["plaza blanda"],
    carouselImages: [
      principal_602,
      carousel2_602,
      carousel3_602,
      carousel4_602,
      carousel5_602,
    ],
  },
  {
    id: 603,
    item: 604,
    name: "PLAZA MONTESORI",
    image: principal_603,
    size: "-",
    age: "2-5 años",
    descripcion:
      ".+ pelotero + tobogán + escalador + triángulo + balancín + sube y baja + caballito mecedor + pata pata + carpita + piso de goma eva",
    filtro: ["plaza blanda"],
    carouselImages: [
      principal_603,
      carousel2_603,
      carousel3_603,
      carousel4_603,
      carousel5_603,
    ],
    consultar: true,
  },
  {
    id: 604,
    item: 605,
    name: "PELOTERO SOFT",
    image: principal_604,
    size: "-",
    age: "2-5 años",
    descripcion: "Pelotero de 2x2 metros con pelotitas color pastel y tobogán.",
    filtro: ["plaza blanda"],
    carouselImages: [
      principal_604,
      carousel2_604,
      carousel3_604,
      carousel4_604,
      carousel5_604,
    ],
    consultar: true,
  },
  // COMBOS
  {
    id: 700,
    item: [6, 504],
    name: "COMBO INFANTIL",
    image: principal_700,
    size: "2,50x4x2",
    age: "2-7 años",
    descripcion: "Combo inflable Infantil + Metegol.",
    filtro: ["combos"],
    carouselImages: [
      principal_700,
      carousel2_700,
      carousel3_700,
      carousel4_700,
      carousel5_700,
    ],
  },
  {
    id: 701,
    item: [10, 503],
    name: "COMBO FANTASIA ",
    image: principal_701,
    size: "4x5x2,50",
    age: "2-9 años",
    descripcion: "Combo inflable Fantasía + Metegol.",
    filtro: ["combos"],
    carouselImages: [
      principal_701,
      carousel2_701,
      carousel3_701,
      carousel4_701,
      carousel5_701,
    ],
  },
  {
    id: 702,
    item: [7, 502],
    name: "COMBO MAGICO ",
    image: principal_702,
    size: "3x5x2,30",
    age: "2-9 años",
    descripcion: "Combo inflable Mágico + Metegol.",
    filtro: ["combos"],
    carouselImages: [
      principal_702,
      carousel2_702,
      carousel3_702,
      carousel4_702,
      carousel5_702,
    ],
  },
  {
    id: 703,
    item: [9, 520, 527],
    name: "COMBO ALEGRIA",
    image: principal_703,
    size: "4x5",
    age: "2-9 años",
    descripcion:
      "Combo inflable Alegría + Juego de Puntería + Pasaficha Doble.",
    filtro: ["combos"],
    carouselImages: [
      principal_703,
      carousel2_703,
      carousel3_703,
      carousel4_703,
      carousel5_703,
    ],
  },
  {
    id: 704,
    item: [8, 517, 526, 503],
    name: "COMBO ELEFANTE ",
    image: principal_704,
    size: "3,50x5x2,20",
    age: "2-9 años",
    descripcion:
      "Combo inflable Elefante + Metegol + Juego de Puntería + Pasaficha Chico.",
    filtro: ["combos"],
    carouselImages: [
      principal_704,
      carousel2_704,
      carousel3_704,
      carousel4_704,
      carousel5_704,
    ],
  },
  {
    id: 705,
    item: 706,
    name: "COMBO PASTEL",
    image: principal_705,
    size: "-",
    age: "2-5 años",
    descripcion: "Combo inflable Candy con pelotero + Plaza temática Pastel.",
    filtro: ["combos"],
    carouselImages: [
      principal_705,
      carousel2_705,
      carousel3_705,
      carousel4_705,
      carousel5_705,
    ],
    consultar: true,
  },
  {
    id: 706,
    item: 707,
    name: "COMBO CAMION",
    image: principal_706,
    size: "-",
    age: "2-5 años",
    descripcion: "Combo inflable Camion + Plaza temática Taller Mecánico.",
    filtro: ["combos"],
    carouselImages: [
      principal_706,
      carousel2_706,
      carousel3_706,
      carousel4_706,
      carousel5_706,
    ],
    consultar: true,
  },
  {
    id: 707,
    item: 708,
    name: "COMBO GRANJA",
    image: principal_707,
    size: "-",
    age: "2-5 años",
    descripcion: "Combo inflable Bosque + Plaza temática Granja.",
    filtro: ["combos"],
    carouselImages: [
      principal_707,
      carousel2_707,
      carousel3_707,
      carousel4_707,
      carousel5_707,
    ],
    consultar: true,
  },
  {
    id: 708,
    item: 709,
    name: "COMBO SAFARI",
    image: principal_708,
    size: "-",
    age: "2-5 años",
    descripcion: "Combo inflable Safari + Plaza temática Safari.",
    filtro: ["combos"],
    carouselImages: [
      principal_708,
      carousel2_708,
      carousel3_708,
      carousel4_708,
      carousel5_708,
    ],
    consultar: true,
  },
  {
    id: 709,
    item: [41, 42, 411, 412],
    name: "COMBO BLANCO",
    image: principal_709,
    size: "-",
    age: "2-8 años",
    descripcion:
      "Combo inflable blanco con pelotero + miniliving blanco liso para 20 chicos.",
    filtro: ["combos"],
    carouselImages: [
      principal_709,
      carousel2_709,
      carousel3_709,
      carousel4_709,
      carousel5_709,
    ],
  },
  {
    id: 710,
    item: [13, 24, 43],
    name: "COMBO SELVATICO",
    image: principal_710,
    size: "-",
    age: "2-9 años",
    descripcion: "Combo inflable Safari + inflable Bosque + inflable Jungla.",
    filtro: ["combos"],
    carouselImages: [
      principal_710,
      carousel2_710,
      carousel3_710,
      carousel4_710,
      carousel5_710,
    ],
  },
  {
    id: 711,
    item: [43, 503],
    name: "COMBO SAFARI + METEGOL",
    image: principal_711,
    size: "-",
    age: "2-8 años",
    descripcion: "Combo inflable Safari + Metegol.",
    filtro: ["combos"],
    carouselImages: [
      principal_711,
      carousel2_711,
      carousel3_711,
      carousel4_711,
      carousel5_711,
    ],
  },
  {
    id: 712,
    item: [43, 408],
    name: "COMBO SAFARI + MINILIVING BLANCO (20)",
    image: principal_712,
    size: "4x4x5",
    age: "2-8 años",
    descripcion: "Combo inflable Safari + Mini Living blanco para 20",
    filtro: ["combos"],
    carouselImages: [
      principal_712,
      carousel2_712,
      carousel3_712,
      carousel4_712,
      carousel5_712,
    ],
  },
  {
    id: 713,
    item: [43, 4, 408],
    name: "COMBO SAFARI CON PELOTERO + MINILIVING BLANCO (20)",
    image: principal_713,
    size: "6x4x5",
    age: "2-8 años",
    descripcion:
      "Combo inflable Safari con Pelotero + Mini Living blanco para 20",
    filtro: ["combos"],
    carouselImages: [
      principal_713,
      carousel2_713,
      carousel3_713,
      carousel4_713,
      carousel5_713,
    ],
  },
  {
    id: 714,
    item: [29, 31],
    name: "COMBO DEPORTIVO 1",
    image: principal_714,
    size: "-",
    age: "5-9 años",
    descripcion:
      "Combo inflable Cancha de Futbol 4x6 + Inflable Arco Ataja Penales.",
    filtro: ["combos"],
    carouselImages: [
      principal_714,
      carousel2_714,
      carousel3_714,
      carousel4_714,
      carousel5_714,
    ],
  },
  {
    id: 715,
    item: [30, 31],
    name: "COMBO DEPORTIVO 2",
    image: principal_715,
    size: "-",
    age: "5-13 años",
    descripcion:
      "Combo inflable Cancha de Futbol 4x9 + Inflable Arco Ataja Penales.",
    filtro: ["combos"],
    carouselImages: [
      principal_715,
      carousel2_715,
      carousel3_715,
      carousel4_715,
      carousel5_715,
    ],
  },
  // COMBOS
  {
    id: 800,
    item: 801,
    name: "CATERING",
    image: principal_800,
    size: "-",
    age: "-",
    descripcion:
      ".+ Finger food + Cortes de carne en sandwich + Cortes de carne especiales + Pernil + Mesa dulce",
    filtro: ["catering"],
    carouselImages: [
      principal_800,
      carousel2_800,
      carousel3_800,
      carousel4_800,
      carousel5_800,
    ],
    consultar: true,
  },
  {
    id: 801,
    item: 802,
    name: "TARDE DE MATE",
    image: principal_801,
    size: "-",
    age: "-",
    descripcion: "Tarde de Mate, Coffe Break, Tapeo, Brunch.",
    filtro: ["catering", "tarde de mate"],
    carouselImages: [principal_801, carousel2_801],
    consultar: true,
  },
];
export default products;

export const sortProducts = [1, 2, 3];
