import { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import Navbar from "../Inicio/Header/components/Navbar"
import Contacto from "../Inicio/Contacto"
import Footer from "../Inicio/Footer"
import Pagination from "../../components/common/Pagination"
import { CorporativeCard, CorporativeHeader } from "./components"
import corporativeData from "../../data/corporative-data"
import "./Corporative.css"

const Corporative = () => {
  const [currentPage, setCurrentPage] = useState(1); const [elementsPerPage] = useState(12);
  const [totalPages, setTotalPages] = useState(
    Math.ceil(corporativeData.length / elementsPerPage)
  );

  const startIndex = (currentPage - 1) * elementsPerPage;
  const endIndex = startIndex + elementsPerPage;

  const [currentElements, setCurrentElements] = useState([]);

  const corporativeDataOrderNewest = corporativeData.reverse()

  useEffect(() => {
    setCurrentElements(corporativeDataOrderNewest.slice(startIndex, endIndex))
  }, [currentPage])

  return (
    <>
      <CorporativeHeader />
      <div className="corporative-container">
        <Grid container spacing={2} style={{ padding: "10px" }}>
          {currentElements.map((corporativeEvent) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={corporativeEvent.id}>
              <CorporativeCard {...corporativeEvent} />
            </Grid>
          ))}
        </Grid>
        <br />
        <Grid container justifyContent="center">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Grid>
        <br />
        <hr style={{ border: "1px solid #fff" }} />
      </div>
      <Contacto />
      <Footer />
    </>
  )
}

export default Corporative
