const corporativeData = [
  {
    id: 1,
    eventName: 'Dia de la Familia',
    place: 'Quilmes',
    organizer: 'Colegio Ausonia',
    date: '15-Oct-11',
    image: require('../assets/images/corporative-imgs/events-images/1.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Ausonia.jpg'),
  },
  {
    id: 2,
    eventName: 'Dia del Niño',
    place: 'Quilmes',
    organizer: 'Colegio Ausonia',
    date: '9-Aug-12',
    image: require('../assets/images/corporative-imgs/events-images/2.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Ausonia.jpg'),
  },
  {
    id: 3,
    eventName: 'Dia del Niño',
    place: 'Hudson',
    organizer: 'Altos de Hudson 1',
    date: '11-Aug-12',
    image: require('../assets/images/corporative-imgs/events-images/3.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Altos_de_Hudson_1.jpg'),
  },
  {
    id: 4,
    eventName: 'Dia del Niño',
    place: 'Gutierrez',
    organizer: 'El Carmen',
    date: '17-Aug-12',
    image: require('../assets/images/corporative-imgs/events-images/4.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/El_Carmen.jpg'),
  },
  {
    id: 5,
    eventName: 'Dia del Niño',
    place: 'Hudson',
    organizer: 'Carmencito',
    date: '17-Aug-12',
    image: require('../assets/images/corporative-imgs/events-images/5.jpg'),
  },
  {
    id: 6,
    eventName: 'Dia de la Familia',
    place: 'Quilmes',
    organizer: 'Colegio Ausonia',
    date: '20-Oct-12',
    image: require('../assets/images/corporative-imgs/events-images/6.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Ausonia.jpg'),
  },
  {
    id: 7,
    eventName: 'Dia de la Familia',
    place: 'Quilmes',
    organizer: 'High School',
    date: '26-Oct-12',
    image: require('../assets/images/corporative-imgs/events-images/7.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/High_School.jpg'),
  },
  {
    id: 8,
    eventName: 'Dia del Niño',
    place: 'Gutierrez',
    organizer: 'El Carmen',
    date: '18-Aug-13',
    image: require('../assets/images/corporative-imgs/events-images/8.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/El_Carmen.jpg'),
  },
  {
    id: 9,
    eventName: 'Dia del Niño',
    place: 'Quilmes',
    organizer: 'Colegio Rios de Vida',
    date: '31-Aug-13',
    image: require('../assets/images/corporative-imgs/events-images/9.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Colegio_Rios_de_Vida.jpg'),
  },
  {
    id: 10,
    eventName: 'Dia del Niño',
    place: 'Ranelagh',
    organizer: 'Ranelagh Golf Club',
    date: '6-Oct-13',
    image: require('../assets/images/corporative-imgs/events-images/10.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Ranelagh_Golf_Club.jpg'),
  },
  {
    id: 11,
    eventName: 'Dia de la Familia',
    place: 'Quilmes',
    organizer: 'High School',
    date: '25-Oct-13',
    image: require('../assets/images/corporative-imgs/events-images/11.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/High_School.jpg'),
  },
  {
    id: 12,
    eventName: 'Dia del Niño',
    place: 'Gutierrez',
    organizer: 'El Carmen',
    date: '10-Aug-14',
    image: require('../assets/images/corporative-imgs/events-images/12.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/El_Carmen.jpg'),
  },
  {
    id: 13,
    eventName: 'Dia de la Familia',
    place: 'Quilmes',
    organizer: 'High School',
    date: '3-Oct-14',
    image: require('../assets/images/corporative-imgs/events-images/13.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/High_School.jpg'),
  },
  {
    id: 14,
    eventName: 'Fiesta de Empresa',
    place: 'Berazategui',
    organizer: 'Dupont',
    date: '18-Dec-14',
    image: require('../assets/images/corporative-imgs/events-images/14.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Dupont.jpg'),
  },
  {
    id: 15,
    eventName: 'Dia del Niño',
    place: 'Gutierrez',
    organizer: 'El Carmen',
    date: '17-Aug-15',
    image: require('../assets/images/corporative-imgs/events-images/15.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/El_Carmen.jpg'),
  },
  {
    id: 16,
    eventName: 'Dia de la Familia',
    place: 'Quilmes',
    organizer: 'Colegio Ausonia',
    date: '17-Oct-15',
    image: require('../assets/images/corporative-imgs/events-images/16.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Ausonia.jpg'),
  },
  {
    id: 17,
    eventName: 'Dia de la Familia',
    place: 'Quilmes',
    organizer: 'High School',
    date: '23-Oct-15',
    image: require('../assets/images/corporative-imgs/events-images/17.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/High_School.jpg'),
  },
  {
    id: 18,
    eventName: 'Dia de la Familia',
    place: 'Gutierrez',
    organizer: 'Colegio Rio de la Plata Sur',
    date: '23-Oct-15',
    image: require('../assets/images/corporative-imgs/events-images/18.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Colegio_Rio_de_La_Plata_Sur.jpg'),
  },
  {
    id: 19,
    eventName: 'Inicio Temporada Verano',
    place: 'Gutierrez',
    organizer: 'Fincas 1',
    date: '4-Jan-16',
    image: require('../assets/images/corporative-imgs/events-images/19.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fincas_1.jpg'),
  },
  {
    id: 20,
    eventName: 'Dia del Niño',
    place: 'Hudson',
    organizer: 'Altos de Hudson 2',
    date: '20-Aug-16',
    image: require('../assets/images/corporative-imgs/events-images/20.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Altos_de_Hudson_2.jpg'),
  },
  {
    id: 21,
    eventName: 'Dia del Niño',
    place: 'Plátanos',
    organizer: 'Golondrinas',
    date: '3-Sep-16',
    image: require('../assets/images/corporative-imgs/events-images/21.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Golondrinas.jpg'),
  },
  {
    id: 22,
    eventName: 'Dia del Niño y la Primavera',
    place: 'Escobar',
    organizer: 'El Aromo',
    date: '18-Sep-16',
    image: require('../assets/images/corporative-imgs/events-images/22.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/El_Aromo.jpg'),
  },
  {
    id: 23,
    eventName: 'Dia de la Familia',
    place: 'Quilmes',
    organizer: 'High School',
    date: '21-Oct-16',
    image: require('../assets/images/corporative-imgs/events-images/23.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/High_School.jpg'),
  },
  {
    id: 24,
    eventName: 'Fiesta de Empresa',
    place: 'Ingeniero Juan Allan',
    organizer: 'Alpargatas',
    date: '2-Dec-16',
    image: require('../assets/images/corporative-imgs/events-images/24.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Alpargatas.jpg'),
  },
  {
    id: 25,
    eventName: 'Fiesta de Empresa',
    place: 'Quilmes',
    organizer: 'Linea 148 Nuevo Halcon',
    date: '23-Dec-16',
    image: require('../assets/images/corporative-imgs/events-images/25.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/El_Nuevo_Halcon_Linea_147.jpg'),
  },
  {
    id: 26,
    eventName: 'Inicio Temporada Verano',
    place: 'Gutierrez',
    organizer: 'Fincas 1',
    date: '4-Jan-17',
    image: require('../assets/images/corporative-imgs/events-images/26.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fincas_1.jpg'),
  },
  {
    id: 27,
    eventName: 'Dia del Niño',
    place: 'Berazategui',
    organizer: 'Fibercord',
    date: '21-Aug-17',
    image: require('../assets/images/corporative-imgs/events-images/27.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fibercord.jpg'),
  },
  {
    id: 28,
    eventName: 'Dia del Niño',
    place: 'Ranelagh',
    organizer: 'Ranelagh Golf Club',
    date: '29-Aug-17',
    image: require('../assets/images/corporative-imgs/events-images/28.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Ranelagh_Golf_Club.jpg'),
  },
  {
    id: 29,
    eventName: 'Dia del Niño',
    place: 'Benavidez',
    organizer: 'Barrio Santa Teresa',
    date: '4-Sep-17',
    image: require('../assets/images/corporative-imgs/events-images/29.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Barrio_Santa_Teresa.jpg'),
  },
  {
    id: 30,
    eventName: 'Dia del Niño',
    place: 'Hudson',
    organizer: 'Altos de Hudson 2',
    date: '16-Sep-17',
    image: require('../assets/images/corporative-imgs/events-images/30.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Altos_de_Hudson_2.jpg'),
  },
  {
    id: 31,
    eventName: 'Dia del Niño',
    place: 'Hudson',
    organizer: 'Fincas de Hudson',
    date: '17-Sep-17',
    image: require('../assets/images/corporative-imgs/events-images/31.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fincas_de_Hudson.jpg'),
  },
  {
    id: 32,
    eventName: 'Dia del Niño',
    place: 'Plátanos',
    organizer: 'Golondrinas',
    date: '23-Sep-17',
    image: require('../assets/images/corporative-imgs/events-images/32.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Golondrinas.jpg'),
  },
  {
    id: 33,
    eventName: 'Dia del Niño',
    place: 'Gutierrez',
    organizer: 'Fincas 1',
    date: '24-Sep-17',
    image: require('../assets/images/corporative-imgs/events-images/33.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fincas_1.jpg'),
  },
  {
    id: 34,
    eventName: 'Dia de la Familia',
    place: 'Quilmes',
    organizer: 'High School',
    date: '20-Oct-17',
    image: require('../assets/images/corporative-imgs/events-images/34.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/High_School.jpg'),
  },
  {
    id: 35,
    eventName: 'Fiesta de Egresados Jardin',
    place: 'Quilmes',
    organizer: 'St. George College',
    date: '13-Nov-17',
    image: require('../assets/images/corporative-imgs/events-images/35.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/St._George_College.jpg'),
  },
  {
    id: 36,
    eventName: 'Dia de la Familia',
    place: 'Quilmes',
    organizer: 'Colegio Rios de Vida',
    date: '21-Nov-17',
    image: require('../assets/images/corporative-imgs/events-images/36.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Colegio_Rios_de_Vida.jpg'),
  },
  {
    id: 37,
    eventName: 'Dia del Niño',
    place: 'Berazategui',
    organizer: 'Fibercord',
    date: '11-Aug-18',
    image: require('../assets/images/corporative-imgs/events-images/37.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fibercord.jpg'),
  },
  {
    id: 38,
    eventName: 'Dia del Niño',
    place: 'Bernal',
    organizer: 'Nueva Escuela del Sur',
    date: '17-Aug-18',
    image: require('../assets/images/corporative-imgs/events-images/38.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Nueva_Escuela_del_Sur.jpg'),
  },
  {
    id: 39,
    eventName: 'Dia del Niño',
    place: 'Quilmes',
    organizer: 'Colegio Ausonia',
    date: '17-Aug-18',
    image: require('../assets/images/corporative-imgs/events-images/39.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Ausonia.jpg'),
  },
  {
    id: 40,
    eventName: 'Dia del Niño',
    place: 'Hudson',
    organizer: 'Altos de Hudson 2',
    date: '25-Aug-18',
    image: require('../assets/images/corporative-imgs/events-images/40.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Altos_de_Hudson_2.jpg'),
  },
  {
    id: 41,
    eventName: 'Dia del Niño',
    place: 'Gutierrez',
    organizer: 'Fincas 1',
    date: '26-Aug-18',
    image: require('../assets/images/corporative-imgs/events-images/41.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fincas_1.jpg'),
  },
  {
    id: 42,
    eventName: 'Dia del Niño',
    place: 'Plátanos',
    organizer: 'Golondrinas',
    date: '9-Sep-18',
    image: require('../assets/images/corporative-imgs/events-images/42.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Golondrinas.jpg'),
  },
  {
    id: 43,
    eventName: 'Dia de la Familia',
    place: 'Quilmes',
    organizer: 'High School',
    date: '26-Oct-18',
    image: require('../assets/images/corporative-imgs/events-images/43.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/High_School.jpg'),
  },
  {
    id: 44,
    eventName: 'Dia del Niño',
    place: 'Berazategui',
    organizer: 'Fibercord',
    date: '10-Aug-19',
    image: require('../assets/images/corporative-imgs/events-images/44.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fibercord.jpg'),
  },
  {
    id: 45,
    eventName: 'Dia del Niño',
    place: 'Quilmes',
    organizer: 'Colegio Ausonia',
    date: '16-Aug-19',
    image: require('../assets/images/corporative-imgs/events-images/45.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Ausonia.jpg'),
  },
  {
    id: 46,
    eventName: 'Dia del Niño',
    place: 'Don Bosco',
    organizer: 'Nuevo Quilmes',
    date: '18-Aug-19',
    image: require('../assets/images/corporative-imgs/events-images/46.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Nuevo_Quilmes.jpg'),
  },
  {
    id: 47,
    eventName: 'Dia del Niño',
    place: 'City Bell',
    organizer: 'Hogar San Patricio',
    date: '19-Aug-19',
    image: require('../assets/images/corporative-imgs/events-images/47.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Hogar_San_Patricio.jpg'),
  },
  {
    id: 48,
    eventName: 'Dia del Niño',
    place: 'Berazategui',
    organizer: 'Los Troncos',
    date: '24-Aug-19',
    image: require('../assets/images/corporative-imgs/events-images/48.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Los_Troncos.jpg'),
  },
  {
    id: 49,
    eventName: 'Dia del Niño',
    place: 'Hudson',
    organizer: 'Country Abril',
    date: '24-Aug-19',
    image: require('../assets/images/corporative-imgs/events-images/49.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Abril.jpg'),
  },
  {
    id: 50,
    eventName: 'Dia del Niño',
    place: 'Gutierrez',
    organizer: 'Fincas 1',
    date: '25-Aug-19',
    image: require('../assets/images/corporative-imgs/events-images/50.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fincas_1.jpg'),
  },
  {
    id: 51,
    eventName: 'Dia del Niño',
    place: 'Plátanos',
    organizer: 'Golondrinas',
    date: '14-Sep-19',
    image: require('../assets/images/corporative-imgs/events-images/51.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Golondrinas.jpg'),
  },
  {
    id: 52,
    eventName: 'Dia de la Familia',
    place: 'Quilmes',
    organizer: 'High School',
    date: '25-Oct-19',
    image: require('../assets/images/corporative-imgs/events-images/52.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/High_School.jpg'),
  },
  {
    id: 53,
    eventName: 'Inicio Temporada Verano',
    place: 'Gutierrez',
    organizer: 'El Carmen',
    date: '4-Jan-20',
    image: require('../assets/images/corporative-imgs/events-images/53.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/El_Carmen.jpg'),
  },
  {
    id: 54,
    eventName: 'Inicio Temporada Verano',
    place: 'Gutierrez',
    organizer: 'Fincas 1',
    date: '8-Jan-20',
    image: require('../assets/images/corporative-imgs/events-images/54.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fincas_1.jpg'),
  },
  {
    id: 55,
    eventName: 'Fiesta Empresa',
    place: 'La Plata',
    organizer: 'Globallogic',
    date: '28-Feb-20',
    image: require('../assets/images/corporative-imgs/events-images/55.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Global_Logic.jpg'),
  },
  {
    id: 56,
    eventName: 'Dia del Niño',
    place: 'City Bell',
    organizer: 'Hogar San Patricio',
    date: '13-Aug-21',
    image: require('../assets/images/corporative-imgs/events-images/56.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Hogar_San_Patricio.jpg'),
  },
  {
    id: 57,
    eventName: 'Dia del Niño',
    place: 'Hudson',
    organizer: 'Country Abril',
    date: '15-Aug-21',
    image: require('../assets/images/corporative-imgs/events-images/57.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Abril.jpg'),
  },
  {
    id: 58,
    eventName: 'Dia del Niño',
    place: 'Quilmes',
    organizer: 'Colegio Innova',
    date: '20-Aug-21',
    image: require('../assets/images/corporative-imgs/events-images/58.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Colegio_Innova.jpg'),
  },
  {
    id: 59,
    eventName: 'Dia del Niño',
    place: 'Quilmes',
    organizer: 'Colegio Innova Jardin',
    date: '23-Aug-21',
    image: require('../assets/images/corporative-imgs/events-images/59.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Colegio_Innova.jpg'),
  },
  {
    id: 60,
    eventName: 'Halloween',
    place: 'Hudson',
    organizer: 'Pueblos del Plata',
    date: '31-Oct-21',
    image: require('../assets/images/corporative-imgs/events-images/60.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Pueblos_del_Plata.jpg'),
  },
  {
    id: 61,
    eventName: 'Inicio Temporada Verano',
    place: 'Gutierrez',
    organizer: 'Fincas 2',
    date: '18-Dec-21',
    image: require('../assets/images/corporative-imgs/events-images/61.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fincas_2.jpg'),
  },
  {
    id: 62,
    eventName: 'Inicio Temporada Verano',
    place: 'City Bell',
    organizer: 'Grand Bell',
    date: '19-Dec-21',
    image: require('../assets/images/corporative-imgs/events-images/62.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Grand_Bell.jpg'),
  },
  {
    id: 63,
    eventName: 'Dia del Niño',
    place: 'City Bell',
    organizer: 'Grand Bell',
    date: '7-Aug-22',
    image: require('../assets/images/corporative-imgs/events-images/63.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Grand_Bell.jpg'),
  },
  {
    id: 64,
    eventName: 'Dia del Niño',
    place: 'Don Bosco',
    organizer: 'Nuevo Quilmes',
    date: '13-Aug-22',
    image: require('../assets/images/corporative-imgs/events-images/64.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Nuevo_Quilmes.jpg'),
  },
  {
    id: 65,
    eventName: 'Dia del Niño',
    place: 'Quilmes',
    organizer: 'Colegio Innova',
    date: '19-Aug-22',
    image: require('../assets/images/corporative-imgs/events-images/65.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Colegio_Innova.jpg'),
  },
  {
    id: 66,
    eventName: 'Dia del Niño',
    place: 'Berazategui',
    organizer: 'Fibercord',
    date: '20-Aug-22',
    image: require('../assets/images/corporative-imgs/events-images/66.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fibercord.jpg'),
  },
  {
    id: 67,
    eventName: 'Dia del Niño',
    place: 'Hudson',
    organizer: 'Pueblos del Plata',
    date: '20-Aug-22',
    image: require('../assets/images/corporative-imgs/events-images/67.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Pueblos_del_Plata.jpg'),
  },
  {
    id: 68,
    eventName: 'Dia del Niño',
    place: 'Gutierrez',
    organizer: 'El Carmen',
    date: '21-Aug-22',
    image: require('../assets/images/corporative-imgs/events-images/68.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/El_Carmen.jpg'),
  },
  {
    id: 69,
    eventName: 'Dia del Niño',
    place: 'El Peligro',
    organizer: 'Expreso Las Sierras',
    date: '27-Aug-22',
    image: require('../assets/images/corporative-imgs/events-images/69.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Expreso_Las_Sierras.jpg'),
  },
  {
    id: 70,
    eventName: 'Maratón',
    place: 'City Bell',
    organizer: 'Grand Bell',
    date: '1-Oct-22',
    image: require('../assets/images/corporative-imgs/events-images/70.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Grand_Bell.jpg'),
  },
  {
    id: 71,
    eventName: 'Fiesta Empresa + Maratón Quilmes A. C.',
    place: 'Quilmes',
    organizer: 'Maxi Descuento. Estadio Quilmes',
    date: '4-Dec-22',
    image: require('../assets/images/corporative-imgs/events-images/71.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Maxi_Descuento.jpg'),
  },
  {
    id: 72,
    eventName: 'Inicio Temporada Verano',
    place: 'Gutierrez',
    organizer: 'El Carmen',
    date: '17-Dec-22',
    image: require('../assets/images/corporative-imgs/events-images/72.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/El_Carmen.jpg'),
  },
  {
    id: 73,
    eventName: 'Fiesta Empresa',
    place: 'Souriges',
    organizer: 'Klover',
    date: '20-Dec-22',
    image: require('../assets/images/corporative-imgs/events-images/73.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Grupo_Klover.jpg'),
  },
  {
    id: 74,
    eventName: 'Inicio Temporada Verano',
    place: 'City Bell',
    organizer: 'Grand Bell',
    date: '23-Dec-22',
    image: require('../assets/images/corporative-imgs/events-images/74.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Grand_Bell.jpg'),
  },
  {
    id: 75,
    eventName: 'Dia Familia',
    place: 'Canning',
    organizer: 'South Creek College',
    date: '15-Apr-23',
    image: require('../assets/images/corporative-imgs/events-images/75.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/South_Creek_School.jpg'),
  },
  {
    id: 76,
    eventName: 'Dia del Niño',
    place: 'Don Bosco',
    organizer: 'Nuevo Quilmes',
    date: '19-Aug-23',
    image: require('../assets/images/corporative-imgs/events-images/76.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Nuevo_Quilmes.jpg'),
  },
  {
    id: 77,
    eventName: 'Dia del Niño',
    place: 'La Plata',
    organizer: 'Globallogic',
    date: '25-Aug-23',
    image: require('../assets/images/corporative-imgs/events-images/77.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Global_Logic.jpg'),
  },
  {
    id: 78,
    eventName: 'Dia del Niño',
    place: 'Hudson',
    organizer: 'Pueblos del Plata',
    date: '26-Aug-23',
    image: require('../assets/images/corporative-imgs/events-images/78.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Pueblos_del_Plata.jpg'),
  },
  {
    id: 79,
    eventName: 'Dia del Niño',
    place: 'Gutierrez',
    organizer: 'Fincas 1',
    date: '27-Aug-23',
    image: require('../assets/images/corporative-imgs/events-images/79.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fincas_1.jpg'),
  },
  {
    id: 80,
    eventName: 'Dia de la Primavera',
    place: 'El Peligro',
    organizer: 'La Candida',
    date: '16-Sep-23',
    image: require('../assets/images/corporative-imgs/events-images/80.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/La_Candida.jpg'),
  },
  {
    id: 81,
    eventName: 'Dia de la Primavera',
    place: 'Gutierrez',
    organizer: 'El Carmen',
    date: '23-Sep-23',
    image: require('../assets/images/corporative-imgs/events-images/81.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/El_Carmen.jpg'),
  },
  {
    id: 82,
    eventName: 'Dia de la Familia',
    place: 'Gutierrez',
    organizer: 'Fincas 2',
    date: '12-Nov-23',
    image: require('../assets/images/corporative-imgs/events-images/82.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fincas_2.jpg'),
  },
  {
    id: 83,
    eventName: 'Inicio Temporada Verano',
    place: 'City Bell',
    organizer: 'Grand Bell',
    date: '22-Dec-23',
    image: require('../assets/images/corporative-imgs/events-images/83.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Grand_Bell.jpg'),
  },
  {
    id: 84,
    eventName: 'Dia del Niño',
    place: 'Quilmes',
    organizer: 'Colegio Ausonia',
    date: '16-Aug-24',
    image: require('../assets/images/corporative-imgs/events-images/84.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Ausonia.jpg'),
  },
  {
    id: 85,
    eventName: 'Dia del Niño',
    place: 'City Bell',
    organizer: 'Hogar San Patricio',
    date: '16-Aug-24',
    image: require('../assets/images/corporative-imgs/events-images/85.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Hogar_San_Patricio.jpg'),
  },
  {
    id: 86,
    eventName: 'Dia del Niño',
    place: 'Don Bosco',
    organizer: 'Nuevo Quilmes',
    date: '17-Aug-24',
    image: require('../assets/images/corporative-imgs/events-images/86.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Nuevo_Quilmes.jpg'),
  },
  {
    id: 87,
    eventName: 'Dia del Niño',
    place: 'Gutierrez',
    organizer: 'El Carmen',
    date: '18-Aug-24',
    image: require('../assets/images/corporative-imgs/events-images/87.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/El_Carmen.jpg'),
  },
  {
    id: 88,
    eventName: 'Dia del Niño',
    place: 'Hudson',
    organizer: 'Fincas de Hudson',
    date: '18-Aug-24',
    image: require('../assets/images/corporative-imgs/events-images/88.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fincas_de_Hudson.jpg'),
  },
  {
    id: 89,
    eventName: 'Dia del Niño',
    place: 'Quilmes',
    organizer: 'Escuela Primaria 43',
    date: '26-Aug-24',
    image: require('../assets/images/corporative-imgs/events-images/89.jpg'),
  },
  {
    id: 90,
    eventName: 'Dia del Niño',
    place: 'La Plata',
    organizer: 'Globallogic',
    date: '5-Sep-24',
    image: require('../assets/images/corporative-imgs/events-images/90.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Global_Logic.jpg'),
  },
  {
    id: 91,
    eventName: 'Dia de la Primavera',
    place: 'Hudson',
    organizer: 'Fincas de Hudson',
    date: '28-Sep-24',
    image: require('../assets/images/corporative-imgs/events-images/91.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fincas_de_Hudson.jpg'),
  },
  {
    id: 92,
    eventName: 'Dia de la Primavera',
    place: 'Hudson',
    organizer: 'Greenville',
    date: '29-Sep-24',
    image: require('../assets/images/corporative-imgs/events-images/92.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Greenville.jpg'),
  },
  {
    id: 93,
    eventName: 'Dia de la Familia',
    place: 'Gutierrez',
    organizer: 'Colegio Rio de la Plata Sur',
    date: '10-Oct-24',
    image: require('../assets/images/corporative-imgs/events-images/93.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Colegio_Rio_de_La_Plata_Sur.jpg'),
  },
  {
    id: 94,
    eventName: 'Halloween',
    place: 'Gutierrez',
    organizer: 'Fincas 2',
    date: '2-Nov-24',
    image: require('../assets/images/corporative-imgs/events-images/94.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Fincas_2.jpg'),
  },
  {
    id: 95,
    eventName: 'Halloween',
    place: 'Gutierrez',
    organizer: 'Village del Parque',
    date: '3-Nov-24',
    image: require('../assets/images/corporative-imgs/events-images/95.jpg'),
  },
  {
    id: 96,
    eventName: 'Festival de Rock',
    place: 'Quilmes',
    organizer: 'Colegio Manuel Belgrano',
    date: '8-Nov-24',
    image: require('../assets/images/corporative-imgs/events-images/96.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Colegio_Manuel_Belgrano.jpg'),
  },
  {
    id: 97,
    eventName: 'Fiesta de Egresados Jardin',
    place: 'Arturo Seguí',
    organizer: 'Jardin de la Cuadra, CABA',
    date: '18-Nov-24',
    image: require('../assets/images/corporative-imgs/events-images/97.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Jardin_de_la_Cuadra.jpg'),
  },
  {
    id: 98,
    eventName: 'Fiesta de Egresados Jardin',
    place: 'Villa Elisa',
    organizer: 'Jardin Arcoiris, Gonnet',
    date: '24-Nov-24',
    image: require('../assets/images/corporative-imgs/events-images/98.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Jardin_Arcoiris.jpg'),
  },
  {
    id: 99,
    eventName: 'Fiesta de Empresa',
    place: 'Quilmes',
    organizer: 'Centro de Rehabilitación ETP',
    date: '25-Nov-24',
    image: require('../assets/images/corporative-imgs/events-images/99.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Centro_de_Rehabilitación_ETP.jpg'),
  },
  {
    id: 100,
    eventName: 'Fiesta de Egresados Jardin',
    place: 'Bernal',
    organizer: 'Jardín Maria Auxiliadora',
    date: '28-Nov-24',
    image: require('../assets/images/corporative-imgs/events-images/100.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Jardin_Maria_Auxiliadora.jpg'),
  },
  {
    id: 101,
    eventName: 'Fiesta de Empresa',
    place: 'Quilmes',
    organizer: 'Pastas Doña Rosa',
    date: '1-Dec-24',
    image: require('../assets/images/corporative-imgs/events-images/101.jpg'),
    organizerLogo: require('../assets/images/corporative-imgs/organizers-logos/Pastas_Doña_Rosa.jpg'),
  },
];

export default corporativeData;
