import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import './CorporativeCard.css';

export const CorporativeCard = ({ id, eventName, place, organizer, date, image, organizerLogo }) => {
  return (
    <div className="corporative-card-container">
      <img className="event-image" src={image} alt={`Imagen del evento ${eventName}`} />
      {organizerLogo && (
        <div className="organizer-logo-container">
          <img className="organizer-logo" src={organizerLogo} alt={`Imagen del logo del organizador ${organizer}`} />
        </div>
      )}
      <div className="corporative-card-details">
        <p>
          <strong>Evento: </strong>
          {eventName}
        </p>
        <p>
          <strong>Organizador: </strong>
          {organizer}
        </p>
        <p>
          <strong>Lugar: </strong>
          {place}
        </p>
        <span>
          <CalendarMonthIcon fontSize="small" />
          {date}
        </span>
      </div>
    </div>
  );
};
